import PropTypes from "prop-types"
import React, { useEffect, useState, forwardRef } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { useHistory } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { get } from "../../helpers/api_helper"

import TopCard from "./topCards"
import Menu from "./Menus"
import OrderCards from "./orderCards"
import OrderCardLarge from "./OrderCardLarge"
import OrderStaticsChart from "./OrderStaticsChart"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const Dashboard = props => {
  const history = useHistory()

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")
  const [placedOrder, setPlacedOrder] = useState(0)
  const [replacementCount, setReplacementCount] = useState(0)
  const [sellerApproval, setSellerApproval] = useState(0)
  const [sellerNewApproval, setSellerNewApproval] = useState(0)
  const [orderStatistics, setOrderStatistics] = useState([])

  useEffect(() => {
    setPeriodData(chartsData)
    // get("/api/v1/order/ordercount/")
    //   .then(res => {
    //     setPlacedOrder(res.count)
    //   })
    //   .catch(err => {})
    // get("/api/v1/order/replacementcount/")
    //   .then(res => {
    //     setReplacementCount(res.count)
    //   })
    //   .catch(err => {})
    // get("/api/v1/seller/seller/request/count/")
    //   .then(res => {
    //     setSellerApproval(res.count)
    //   })
    //   .catch(err => {})
    // get("/api/v1/product/product_approval/")
    //   .then(res => {
    //     setSellerNewApproval(res.count)
    //   })
    // .catch(err => {})
    // get("/api/v1/order/admin/order_statistics/")
    //   .then(res => {
    //     setOrderStatistics(res)

    //     // console.log(res, "order data")
    //   })
    // .catch(err => {})
  }, [chartsData])

  let checkDate = ""

  function getNextPaymentDate() {
    const today = new Date()
    const date = today.getDate()

    if (date <= 14) {
      let diff = 14 - date

      if (diff === 0) {
        checkDate = "TODAY"
      } else if (diff === 1) {
        checkDate = "TOMMOROW"
      }
      const formattedDate = `14-${today.getMonth() + 1}-${today.getFullYear()}`
      return formattedDate
    } else if (date <= 28) {
      let diff = 28 - date
      if (diff === 0) {
        checkDate = "TODAY"
      } else if (diff === 1) {
        checkDate = "TOMMOROW"
      }
      const formattedDate = `28-${today.getMonth() + 1}-${today.getFullYear()}`
      return formattedDate
    } else {
      let month = today.getMonth() + 2
      let year = today.getFullYear()
      if (month == 13) {
        month = "01"
        year = year + 1
      }
      const formattedDate = `14-${month}-${year}`

      return formattedDate
    }
  }

  const [orderData, setorderData] = useState([])
  const [dateRange, setDateRange] = useState({})

  const [isThisMonth, setisThisMonth] = useState(true)

  function getDateRange() {
    const today = new Date()
    const year = today.getFullYear()
    const month = today.getMonth() + 1
    const day = today.getDate()

    const datefrom = `${year}-${month < 10 ? "0" : ""}${month}-01`

    const dateto = `${year}-${month < 10 ? "0" : ""}${month}-${
      day < 10 ? "0" : ""
    }${day}`

    setDateRange({
      startDate: new Date(datefrom),
      endDate: new Date(dateto),
    })
    getOrderStatics(datefrom, dateto)
  }

  useEffect(async () => {
    getDateRange()
  }, [])

  const getOrderStatics = (datefrom, dateto) => {
    get(
      `/api/v1/order/admin/order_statistics/?datefrom=${
        datefrom
        // "2021-07-01"
      }&dateto=${dateto}`
    )
      .then(res => {
        console.log(res, "fvgsjhkjdhlsd")

        setOrderStatistics(res)

        //   const inputData = res?.ORDER?.order_status

        //   const transformedData = [
        //     {
        //       name: "NEW ORDER",
        //       value: inputData["New Order"].total_price,
        //       count: inputData["New Order"].count,
        //     },
        //     {
        //       name: "APPROVED",
        //       value: inputData["ORDER APPROVED"].total_price,
        //       count: inputData["ORDER APPROVED"].count,
        //     },
        //     {
        //       name: "SHIPPED",
        //       value: inputData["SHIPPED"].total_price,
        //       count: inputData["SHIPPED"].count,
        //     },
        //     {
        //       name: "DELIVERED",
        //       value: inputData["DELIVERED"].total_price,
        //       count: inputData["DELIVERED"].count,
        //     },
        //     {
        //       name: "CANCELLED",
        //       value: inputData["CANCELLED"].total_price,
        //       count: inputData["CANCELLED"].count,
        //     },
        //   ]
        //   setorderData(transformedData)
      })

      .catch(err => {
        setorderData([])
        setOrderStatistics([])
      })
  }

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="p-2 btn btn-light font-size-16"
      style={{ whiteSpace: "nowrap" }}
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  ))

  const onChangeDate = (fromDate, toDate) => {
    // console.log(fromDate, toDate, "fromDate, toDate")

    setDateRange({
      startDate: fromDate,
      endDate: toDate,
    })

    const currentDate = new Date()
    const isFirstDayOfMonth =
      fromDate.getDate() === 1 &&
      fromDate.getMonth() === currentDate.getMonth() &&
      fromDate.getFullYear() === currentDate.getFullYear()

    // Check if toDate is the current day
    const isCurrentDay =
      toDate.getDate() === currentDate.getDate() &&
      toDate.getMonth() === currentDate.getMonth() &&
      toDate.getFullYear() === currentDate.getFullYear()

    // console.log(isFirstDayOfMonth, isCurrentDay, "isFirstDayOfMonth2")

    if (isFirstDayOfMonth && isCurrentDay) {
      setisThisMonth(true)
    } else {
      setisThisMonth(false)
    }

    const datefrom = formatDate(fromDate)
    const dateto = formatDate(toDate)
    getOrderStatics(datefrom, dateto)
  }

  const formatDate = date => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return `${year}-${month < 10 ? "0" : ""}${month}-${
      day < 10 ? "0" : ""
    }${day}`
  }
  const formatDate2 = date => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return `${day < 10 ? "0" : ""}${day}-${
      month < 10 ? "0" : ""
    }${month}-${year}`
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | BrakeUp</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Row>
            <Col md="4" className="order-3 order-md-1">
              <Card
                className="mini-stats-wid"
                onClick={() => history.push(props.url)}
              >
                <CardBody>
                  <p className="fw-bold text-muted font-size-14">
                    Order Performance Range
                  </p>
                  <div className="d-flex justify-content-between">
                    <div className="position-relative d-flex align-items-center">
                      <DatePicker
                        selected={dateRange?.startDate}
                        onChange={date =>
                          onChangeDate(date, dateRange?.endDate)
                        }
                        selectsStart
                        startDate={dateRange?.startDate}
                        endDate={dateRange?.endDate}
                        maxDate={new Date()}
                        dateFormat="dd-MM-yyyy"
                        closeOnScroll={e => e.target === document}
                        customInput={<ExampleCustomInput />}
                      />
                      <span className="fw-bold text-muted ms-3 me-3">-</span>
                      <DatePicker
                        selected={dateRange?.endDate}
                        onChange={date =>
                          onChangeDate(dateRange?.startDate, date)
                        }
                        selectsEnd
                        startDate={dateRange?.startDate}
                        endDate={dateRange?.endDate}
                        minDate={dateRange?.startDate}
                        maxDate={new Date()}
                        dateFormat="dd-MM-yyyy"
                        closeOnScroll={e => e.target === document}
                        customInput={<ExampleCustomInput />}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="1" md="4" className="order-2" />
            <Col md="4" className="order-1 order-md-3">
              <TopCard
                title={"Next Payment Date"}
                description={getNextPaymentDate()}
                iconClass="bx-calendar"
                url="/calendar"
                day={checkDate}
              />
            </Col>

            {/* <Col className="position-relative" md="8">
              <Row>
                <div className="mb-0">
                  <Card body className="mb-0 p-0">
                    <button
                      className="btn flex align-items-center text-start  "
                      style={{ background: "#f3f3f3" }}
                    >
                      <span className=" font-size-16 font-weight-semibold  text-uppercase">
                        Order Performance{" "}
                        {isThisMonth ? (
                          " This month"
                        ) : (
                          <>
                            from{" "}
                            <span style={{ whiteSpace: "nowrap" }}>
                              {formatDate2(dateRange?.startDate)}
                            </span>{" "}
                            to{" "}
                            <span style={{ whiteSpace: "nowrap" }}>
                              {formatDate2(dateRange?.endDate)}
                            </span>
                          </>
                        )}
                      </span>
                    </button>
                  </Card>
                </div>
              </Row>
              {orderData && orderData.length != 0 ? (
                <OrderStaticsChart data={orderData} />
              ) : (
                <div
                  className="h-100 d-flex justify-content-center align-items-center"
                  style={{ minHeight: "200px" }}
                >
                  <p className="mb-5"> No Data available </p>
                </div>
              )}
            </Col> */}
          </Row>

          <Row>
            <Card body className="text-center mb-0 mb-md-4 ">
              <button
                className="btn flex align-items-center "
                style={{ background: "#e5e5e5" }}
              >
                <i className="mdi mdi-chart-bar font-size-20 font-weight-semibold me-2"></i>
                <span className="font-size-18 font-weight-semibold  text-uppercase">
                  Order Performance Statistics{" "}
                  {isThisMonth ? (
                    " This month"
                  ) : (
                    <>
                      from{" "}
                      <span style={{ whiteSpace: "nowrap" }}>
                        {formatDate2(dateRange?.startDate)}
                      </span>{" "}
                      to{" "}
                      <span style={{ whiteSpace: "nowrap" }}>
                        {formatDate2(dateRange?.endDate)}
                      </span>
                    </>
                  )}
                </span>
              </button>
            </Card>
          </Row>

          <Row>
            {orderStatistics && orderStatistics.length != 0 ? (
              <>
                <Col md="3" className="pb-4">
                  <Card
                    className=""
                    style={{ background: "#cfd7ff", height: "100%" }}
                    onClick={() => history.push("/orders")}
                  >
                    <CardBody className="py-3 px-2 d-flex flex-column justify-content-center align-items-center">
                      <p className={`fw-medium text-center font-size-22 `}>
                        ORDERS {orderStatistics?.total_orders?.count}
                      </p>
                      <h6 className={`mb-0 text-center ${"font-size-20"}`}>
                        Rs {orderStatistics?.total_orders?.total_price}
                      </h6>
                    </CardBody>
                  </Card>
                </Col>

                <Col>
                  <Row className="grid">
                    <OrderCards
                      title={`NEW ORDERS ${orderStatistics?.ORDER?.order_status?.["New Order"]?.count}`}
                      url="/orders"
                      bg={{ background: "#cfd7ff" }}
                      number={
                        orderStatistics?.ORDER?.order_percent?.["New Order"] +
                        " %"
                      }
                      amount={`Rs ${orderStatistics?.ORDER?.order_status?.["New Order"]?.total_price}`}
                    />

                    <OrderCards
                      title={`UNSHIPPED ${orderStatistics?.ORDER?.order_status?.["PENDING SHIPPED"]?.count}`}
                      url="/orders"
                      bg={{ background: "#cfd7ff" }}
                      number={
                        orderStatistics?.ORDER?.order_percent?.[
                          "PENDING SHIPPED"
                        ] + " %"
                      }
                      amount={`Rs ${orderStatistics?.ORDER?.order_status?.["PENDING SHIPPED"]?.total_price}`}
                    />

                    <OrderCards
                      title={`SHIPPED ${orderStatistics?.ORDER?.order_status?.SHIPPED?.count}`}
                      url="/orders"
                      bg={{ background: "#cfd7ff" }}
                      number={
                        orderStatistics?.ORDER?.order_percent?.SHIPPED + " %"
                      }
                      amount={`Rs ${orderStatistics?.ORDER?.order_status?.SHIPPED?.total_price}`}
                    />
                    <OrderCards
                      title={`DELIVERED ${orderStatistics?.ORDER?.order_status?.DELIVERED?.count}`}
                      url="/orders"
                      bg={{ background: "#cfd7ff" }}
                      number={
                        orderStatistics?.ORDER?.order_percent?.DELIVERED + " %"
                      }
                      amount={`Rs ${orderStatistics?.ORDER?.order_status?.DELIVERED?.total_price}`}
                    />
                    <OrderCards
                      title={`RETURNING ${orderStatistics?.ORDER?.order_status?.RETURNING?.count}`}
                      url="/orders"
                      bg={{ background: "#cfd7ff" }}
                      number={
                        orderStatistics?.ORDER?.order_percent?.RETURNING + " %"
                      }
                      amount={`Rs ${orderStatistics?.ORDER?.order_status?.RETURNING?.total_price}`}
                    />

                    <OrderCards
                      title={`CANCELLED ${orderStatistics?.ORDER?.order_status?.CANCELLED?.count}`}
                      url="/orders"
                      bg={{ background: "#cfd7ff" }}
                      number={
                        orderStatistics?.ORDER?.order_percent?.CANCELLED + " %"
                      }
                      amount={`Rs ${orderStatistics?.ORDER?.order_status?.CANCELLED?.total_price}`}
                    />
                  </Row>
                </Col>
              </>
            ) : (
              <Row
                className="h-100 d-flex justify-content-center text-center align-items-center"
                style={{ minHeight: "260px" }}
              >
                <p className="mb-5"> No Data available </p>
              </Row>
            )}
          </Row>

          <Row>
            {/* <Col xl="6"> */}
            <Card body className="text-center mb-0 mb-md-4">
              <button
                className="btn btn-secondary  flex align-items-center "
                style={{ background: "#a4a4a4" }}
              >
                <i className="mdi mdi-cogs font-size-20 font-weight-semibold me-2"></i>
                <span className=" font-size-18 font-weight-semibold  text-uppercase">
                  Management & Configuration
                </span>
              </button>
            </Card>
            {/* </Col> */}
          </Row>
          {/* <br />
          <Row>
            <Col xl="6">
              <Card body className="text-center">
                <button
                  type="button"
                  className="btn btn-secondary "
                  onClick={() => history.push("/seller/")}
                >
                  <i className="mdi mdi-bell font-size-16 align-middle me-2"></i>
                  Notification For Seller Approval {sellerApproval}
                </button>
              </Card>
            </Col>
            <Col xl="6">
              <Card body className="text-center">
                <button
                  type="button"
                  className="btn btn-secondary "
                  onClick={() => history.push("/products/")}
                >
                  <i className="mdi mdi-bell font-size-16 align-middle me-2"></i>
                  Notification For New Product {sellerNewApproval}
                </button>
              </Card>
            </Col>
          </Row>
          <br /> */}
          <Row>
            <Col xl="12">
              <Row>
                <Menu
                  onclick={() => history.push("/orders")}
                  color={"success"}
                  title={"Orders"}
                  icon="mdi mdi-order-bool-descending"
                  count={""}
                />
                <Menu
                  onclick={() => history.push("/buyers")}
                  color={"primary"}
                  title={"Buyers"}
                  icon="mdi mdi-cart-arrow-down"
                />
                <Menu
                  onclick={() => history.push("/seller")}
                  color={"info"}
                  title={"Sellers"}
                  icon="mdi mdi-cart-arrow-up"
                  count={""}
                />
                <Menu
                  onclick={() => history.push("/products")}
                  color={"warning"}
                  title={"Products"}
                  icon="mdi mdi-shopping"
                />
                <Menu
                  onclick={() => history.push("/profile")}
                  color={"danger"}
                  title={"Accounts"}
                  icon="mdi mdi-account"
                />
                {/* <Menu
                  onclick={() => history.push("/user-control")}
                  color={"primary"}
                  title={"User Control Panel"}
                  icon="mdi mdi-account-group"
                /> */}

                <Menu
                  onclick={() => history.push("/vehicles")}
                  color={"pink"}
                  title={"Vehicles"}
                  icon="bx bx-car"
                />
                <Menu
                  onclick={() => history.push("/promocode-list")}
                  color={"secondary"}
                  title={"Promocode"}
                  icon="bx bxs-discount"
                />
                <Menu
                  onclick={() => history.push("/reports")}
                  color={"dark"}
                  title={"Reports"}
                  icon="bx bxs-report"
                />
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
}

export default withTranslation()(Dashboard)
