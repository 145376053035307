import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import MetaTags from 'react-meta-tags';

import {
    Button,
    Card,
    CardBody,
    CardSubtitle,
    CardTitle,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    TabContent,
    Table,
    TabPane,
} from "reactstrap"
import Select from "react-select"
import Dropzone from "react-dropzone"
import { API_URL } from "../../helpers/api_helper";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { CustomInput, CustomSelect } from "../Ecommerce/customInputs";
import { post, get } from "../../helpers/api_helper";
import axios from 'axios';
import StarRatings from "react-star-ratings"
import { Loader } from "../../helpers/loader";
import { useParams } from "react-router-dom";
import { ErrorToast } from "../../helpers/toast";
import { useHistory } from "react-router-dom";
import {success, error} from "../../service/alerts";

const AddModel = () => {
    const { handleSubmit, errors, control, setValue, watch, register, unregister } = useForm({
        mode: 'onChange'
    });
    const history = useHistory();
    const [selectedFiles, setselectedFiles] = useState([])
    const [fuelType, setFuelType] = useState("Petrol");
    const [loader, setLoader] = useState(false);
    const [imageLoader, setImageLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    const [message, setMessage] = useState("")
    const { id } = useParams();
    const [make, setMake] = useState([]);
    const getProductDetails = (id) => {
        get(`/api/v1/vehiclemodel/?model_id=${id}`).then(res=>{
            if(res['data'][0].image){
                setselectedFiles([res['data'][0].image]);
            }
            setValue("make", {
                label: res['data'][0].make,
                value: res['data'][0].make_id
            })
            setValue("model", res['data'][0].model);
            // setFuelType(res['data'][0].fuel_type);
        }).catch(err=>{

        })
    }
    useEffect(() => {
        if (id) { getProductDetails(id); }

    }, [id])
    const handleAcceptedFiles = (acceptedFiles) => {
        let formData = new FormData();
        formData.append("name", acceptedFiles[0].name);
        formData.append("type", "product");
        formData.append("file", acceptedFiles[0]);
        setImageLoader(true);
        fetch( API_URL + `/api/v1/products/uploadimage/`,      
        {
                method: 'POST',
                body: formData
            }
        )
            .then(response => response.json())
            .then(res => {
                let selected = [];
                selected.push(`${res.url}`);
                setselectedFiles(selected);
                setImageLoader(false);
            })
    }
    const getMake = async()=>{
        const result = await get("/api/v1/vehiclemake/");
        if(result.data){
            let inputs = [];
            result.data && result.data.map(item=>{
                if(typeof(item.make) === "string"){
                    inputs.push({
                        label: item.make,
                        value: item._id["$oid"]
                    })
                }
            })
          setMake(inputs)
        }else{
          setMake([]);
        }
    }
    useEffect(() => {
        getMake();
    },[]);
    const onSubmit = async (items) => {
        // if(selectedFiles.length ==0){
        //     error("Please add image");
        //     return
        // }
        // if(!fuelType){
        //     error("Please select fuel type");
        //     return
        // }
        let values = {
                model:items.model,
                image:selectedFiles.length >0? selectedFiles[0]:"",
                // fuel_type:fuelType,
                id: items.make.value
                }
        setLoader(true);
        // return
        try {
            let urls = "/api/v1/vehiclemodel/";
            if (id) {
                urls = "/api/v1/update_model/";
                values.id = id;
            }
            post(urls, values).then(res => {
                success("able to create model");
                setLoader(false);
                history.push("/AddVehicles/");
            }).catch(err => {
                setLoader(false);
                setShowError(true);
                error(err.response.data.message)
            });


        } catch (err) {
            setLoader(false);
        }
    }
    return (
        <React.Fragment>
            <div className="page-content">
                {showError && <ErrorToast
                    message={message}
                    showError={showError}
                    setShowError={setShowError} />}
                <MetaTags>
                    <title>Add Model |  BrakeUp</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Commercial" breadcrumbItem="Add Model" />

                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Row>
                                            <Col sm="5">
                                            <div className="mb-3">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Select Make
                                                    </Label>
                                                    <Col sm={12}>
                                                        <div className="form-floating mb-3">
                                                            <Controller
                                                                as={<CustomSelect />}
                                                                defaultValue={null}
                                                                name={"make"}
                                                                options={make}
                                                                onChange={([e]) => {
                                                                    return { value: e };
                                                                }}
                                                                control={control}
                                                                rules={{ required: true }}
                                                            />
                                                            {errors.make && <span style={{ color: "red" }}>Make is required.</span>}

                                                        </div>
                                                    </Col>
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="productname">Model Name</Label>
                                                    <Controller
                                                        as={<CustomInput />}
                                                        defaultValue={null}
                                                        name="model"
                                                        onChange={([e]) => {
                                                            return { value: e };
                                                        }}
                                                        control={control}
                                                        rules={{ required: true }}
                                                    />
                                                    {errors.model && <span style={{ color: "red" }}>Model name is required.</span>}
                                                </div>
                                                {/* <Row>
                                                    <Col lg={4}>
                                                        <div className="form-check mb-3">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="exampleRadios"
                                                                id="exampleRadios1"
                                                                value="Petrol"
                                                                checked={fuelType == 'Petrol'}
                                                                onClick={() => setFuelType('Petrol')}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="exampleRadios1"
                                                            >
                                                                Petrol
                                                            </label>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="exampleRadios"
                                                                id="exampleRadios2"
                                                                value="Diesel"
                                                                checked={fuelType == 'Diesel'}
                                                                onClick={() => setFuelType('Diesel')}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="exampleRadios2"
                                                            >
                                                                Diesel
                                                            </label>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="exampleRadios"
                                                                id="exampleRadios2"
                                                                value="Electric"
                                                                checked={fuelType == 'Electric'}
                                                                onClick={() => setFuelType('Electric')}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="exampleRadios2"
                                                            >
                                                                Electric
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </Row> */}
                                                <div className="mb-3">
                                                    <Dropzone
                                                        onDrop={acceptedFiles => {
                                                            handleAcceptedFiles(acceptedFiles)
                                                        }}
                                                    >
                                                        {({ getRootProps, getInputProps }) => (
                                                            <div
                                                                {...getRootProps()}
                                                                style={{
                                                                    height: "160px",
                                                                    border: "1px dashed #60e0ff",
                                                                    textAlign: "center",
                                                                    cursor: "pointer"
                                                                }}
                                                            >
                                                                <input {...getInputProps()} />
                                                                {imageLoader && <Loader />}
                                                                {selectedFiles.length == 0 && <h4 style={{ marginTop: "64px" }}>Add Image</h4>}
                                                                {selectedFiles.map((f, i) => {
                                                                return (
                                                                    <img
                                                                    data-dz-thumbnail=""
                                                                    height="80"
                                                                    className="avatar-sm rounded bg-light"
                                                                    style={{ width: "100%", height: "100%" }}
                                                                    alt={"image"}
                                                                    src={f}
                                                                    />)
                                                                })
                                                                }
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="d-flex flex-wrap gap-2">
                                            {loader && <Loader />}
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="btn "
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default AddModel
