import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  Button
} from "reactstrap"
import classnames from "classnames"
import { isEmpty } from "lodash"
import "nouislider/distribute/nouislider.css"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
//redux
import { get, post } from "../../helpers/api_helper";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { success, error, Loader } from "../../service/alerts";

const ProductsByVersions = props => {
  const { history } = props
  const [productList, setProductList] = useState([]);
  const [versions, setVersions] = useState([]);
  const [activeTab, setActiveTab] = useState("1")
  // eslint-disable-next-line no-unused-vars

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  const [products, setProducts] = useState([]);
  const {
    match: { params },
  } = props
  const getProducts = async () => {
    const result = await get(`/api/v1/vehiclemodel/?model_id=${params.id}`);
    if (result.data) {
      setProductList(result.data)
    } else {
      setProductList([]);
    }
    const results = await get(`/api/v1/get_model_product_details/?id=${params.id}`);
    console.log(results, 'resultsresultsresults')
    if (results) {
      setVersions(results)
    } else {
      setVersions([]);
    }
  }
  useEffect(() => {
    getProducts();
  }, [])
  const handlePageClick = page => {
    setPage(page)
  }
  const deleteMake = (id) => {
    post("/api/v1/delete_make/", { id: id }).then(res => {
      success("able to delete");
      getProducts();
    }).catch(err => {
      error("sorry not able delete");
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Vehicles |  BrakeUp</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Commercial" breadcrumbItem="Version" />
          <Row>
            <Col lg="12">
              <Row className="">
                <Col xl="4" sm="6">

                </Col>
                <Col lg="8" sm="6">
                  <Form className="mt-4 mt-sm-0 float-sm-end d-flex align-items-center">
                    <div className="search-box me-2">
                      <div className="position-relative">
                        <Input
                          type="text"
                          className="form-control border-0"
                          placeholder="Search..."
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                    {/* <Nav className="product-view-nav" pills>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "1",
                          })}
                          onClick={() => {
                            toggleTab("1")
                          }}
                        >
                          <i className="bx bx-grid-alt" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "2",
                          })}
                          onClick={() => {
                            toggleTab("2")
                          }}
                        >
                          <i className="bx bx-list-ul" />
                        </NavLink>
                      </NavItem>
                    </Nav> */}
                  </Form>
                </Col>
              </Row>
              <Row>
                {!isEmpty(productList) &&
                  productList.map((product, key) => (
                    <Col xl="12" sm="12" key={"_col_" + key}>
                      <Card>
                        <CardBody className="py-1">
                          <Row>
                            {/* <Col xl="3" sm="4">
                              <div className="product-img position-relative">
                                <img
                                  src={product.image? product.image[0] && product.image[0].startsWith("https://") ? product.image[0] :
                                    `https://${product.image[0]}`:""

                                  }
                                  alt=""
                                  className="img-fluid mx-auto d-block"
                                />
                              </div>
                            </Col> */}
                            <Col xl="6" sm="6">
                              <div className="mt-4 ">
                                <h5 className="mb-3 text-truncate">
                                  MODEL NAME: {product.model}
                                </h5>
                                <span className="text-muted me-2">
                                  Total Versions: {product.total_versions}
                                </span><br></br>
                              </div>
                            </Col>
                            <Col xl="6" sm="6">
                              <div className="mt-4 ">
                                <span className="text-muted me-2">
                                  Total Products: {product.total_products_model}
                                </span><br></br>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>

                      </Card>
                    </Col>
                  ))}
              </Row>
              <Row>
                {!isEmpty(versions) &&
                  versions.map((product, key) => (
                    <Col xl="3" sm="4" key={"_col_" + key}>
                      <Card>
                        <CardBody>
                          <Row>
                          <Link to={"/ecommerce-product-detail/" + product?._id.$oid} >
                              <h4 className="me-2 text-truncate" >
                                {product?.prod_name}
                              </h4>
                            </Link>
                            <Col xl="5" sm="12">
                              <div className="product-img position-relative">
                                <h5 className="mb-2 text-truncate">
                                  <Link
                                    className="text-dark"
                                  >
                                    {/* {product.version} */}
                                  </Link>
                                </h5>
                                <Link to={"/ecommerce-product-detail/" + product?._id.$oid} >
                                <img
                                  src={
                                    product?.image ? product.image[0] && product?.image[0].startsWith("https://") ? product?.image[0] :
                                      `https://${product.image[0]}` : ""

                                  }
                                  alt=""
                                  className="img-fluid mx-auto d-block"
                                  style={{ height: '100px' }}
                                />
                                </Link>
                              </div>
                            </Col>
                            <Col xl='7'>
                              <Col xl="12">
                                <div className="mt-2">
                                  <span className="text-muted me-2 text-truncate">
                                    CATEGORY: {product?.category}
                                  </span>
                                </div>
                              </Col>
                              <Col xl="12">
                                <div className="mt-2">
                                  <span className="text-muted me-2 text-truncate">
                                    BRAND: {product?.brand}
                                  </span>
                                </div>
                              </Col>
                              <Col xl="12">
                                <div className="mt-2">
                                  <span className="text-muted me-2 text-truncate">
                                    TYPE: {product?.type}
                                  </span>
                                </div>
                              </Col>

                              <Col xl="12">
                                <div className="mt-2">
                                  <span className="text-muted me-2">
                                    STOCK: {product?.total_stock}
                                  </span>
                                </div>
                              </Col>
                            </Col>

                            <Col xl="12">
                              <div className="mt-2">
                                <span className="text-muted me-2">
                                  PROD ID: {product?.product_id}
                                </span>
                              </div>
                            </Col>
                            <Col xl="12">
                              <div className="mt-2">
                                <span className="text-muted me-2 text-truncate">
                                  SELLER PART NO: {product?.seller_part_no}
                                </span>
                              </div>
                            </Col>
                            {product?.vendor_products?.selling_price &&
                              <Col xl="12">
                                <div className="mt-2">
                                  <span className="text-muted me-2">
                                    SELLING PRICE: {product?.vendor_products?.selling_price}
                                  </span>
                                </div>
                              </Col>}
                            <Col xl="12">
                              <div className="mt-2">
                                <span className="text-muted me-2">
                                  MRP: {product?.mrp}
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ProductsByVersions.propTypes = {
  products: PropTypes.array,
  history: PropTypes.object,
  onGetProducts: PropTypes.func,
}

export default withRouter(ProductsByVersions)
