import React, { useState, useEffect } from "react"
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { CustomInput, CustomSelect } from "../Ecommerce/customInputs";
import {
    Button,
    Label,
    Row,
    Col,
    Table
} from "reactstrap";
import { AllProducts, AllBrands, AllCategorys, Product } from "./productFiler";
import { post, get } from "../../helpers/api_helper";


const InCludedProducts = (props) => {
    const [filter, setFilter] = useState([]);
    const [hide, setHide] = useState(false);
    const [products, setProducts] = useState([]);
    useEffect(() => {
        if (props.secondForm && props.secondForm.filter) {
            console.log(props.secondForm, 'props.secondForm.filterprops.secondForm.filter')
            setFilter(props.secondForm.filter);
            const item = props.secondForm.filter.filter(items => {
                return items.type == "allproducts";
            })
            if (item && item.length > 0) {
                setHide(true);
            }
            getProducts(props.secondForm.filter);
        }
    }, [props.secondForm])
    const productFilerData = (values) => {
        let newItems = [...filter];
        if (values.type == "allproducts") {
            newItems = [];
            setHide(true);
        }
        newItems.push(values);
        setFilter(newItems);
        getProducts(newItems);
    }
    const getProducts = (inputs) => {

        console.log(inputs, "inputsinputs");

        let out = [];
        inputs.map(values => {
            if (values.type == "allproducts") {
                out.push({
                    "type": "allproducts"
                })
            } else if (values.type == "brand") {
                out.push({
                    "type": "brand",
                    "brand_id": values?.brand?.value
                })
            }
            else if (values.type == "category") {
                out.push({
                    "type": "category",
                    "category_id": values?.category?.value
                })
            }
            else if (values.type == "brand_product") {
                out.push({
                    "type": "brand_product",
                    "product_id": values?.sellerPartNo?.value,
                    "brand_id": values.brand_id,
                    "seller_part_no": values?.seller_part_no,
                })
            }
        })
        post("/api/v1/promocode/applicable_products/", { "data": out }).then(res => {
            setProducts(res.applicable_products);
        }).catch(err => {
            setProducts([]);
        })
    }

    return (
        <Row>
            <Col lg={6}>
                <Col lg={13}>
                    <h4>Applicability</h4>
                    <AllProducts productFilerData={productFilerData} hide={hide} />
                    <AllBrands productFilerData={productFilerData} hide={hide} />
                    <AllCategorys productFilerData={productFilerData} hide={hide} />
                    <Product productFilerData={productFilerData} hide={hide} />

                </Col>
                <Col lg={12}>
                    <div className="mt-5">
                        <div className="table-responsive">
                            <Table className="table mb-0 table-bordered">
                                <tbody>
                                    <tr key="header">
                                        <th

                                        >
                                            Name
                                        </th>
                                        <th

                                        >
                                            Type
                                        </th>
                                        <th>Action</th>
                                    </tr>
                                    {filter &&
                                        filter.map((items, i) => (
                                            <tr key={i}>
                                                <td>
                                                    {items.name}
                                                </td>
                                                <td>
                                                    {items.type}
                                                </td>
                                                <td style={{ cursor: "pointer" }} onClick={() => {
                                                    let values = [...filter];
                                                    if (values[i].type == "allproducts") {
                                                        setHide(false);
                                                    }
                                                    values.splice(i, 1);
                                                    setFilter(values);
                                                    getProducts(values);
                                                }}>Remove</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <Button
                        color="primary"
                        className="w-md"
                        style={{ float: "right", marginTop: "15px" }}
                        onClick={() => {
                            let out = {
                                filter: [...filter],
                                applicable_products: [...products]
                            }
                            props.onSubmit(out)

                        }}
                    >
                        Save and Continue
                    </Button>
                </Col>
            </Col>
            <Col lg={6}>
                <h4>Products</h4>
                <div className="mt-5">
                    <div className="table-responsive">
                        <Table className="table mb-0 table-bordered">
                            <tbody>
                                <tr key="header">
                                    <th>
                                        Name
                                    </th>
                                    <th>
                                        Category
                                    </th>
                                    <th>
                                        Brand
                                    </th>
                                    <th>
                                        Image
                                    </th>
                                </tr>
                                {products &&
                                    products.map((items, i) => (
                                        <tr key={i}>
                                            <td>
                                                {items.prod_name}
                                            </td>
                                            <td>
                                                {items.category}
                                            </td>
                                            <td>
                                                {items.brand}
                                            </td>
                                            <td>
                                                <img src={items.image ? items.image[0] : ""} style={{ width: "50px" }}></img>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </Col>
        </Row>
    )
}
export default InCludedProducts;