import PropTypes from "prop-types"
import React from "react"
import MetaTags from "react-meta-tags"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Badge
} from "reactstrap"
import { useHistory } from 'react-router-dom'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"

const PromoCode = props => {
    const history = useHistory()
    const Menu = (props) => {
        return (
            <React.Fragment>
                <Col onClick={props.onclick} lg={6}>
                    <Card color={props.color} className="cursor-pointer text-white-50 h-90">
                        {
                            props.count &&
                            <h3><Badge color="secondary" style={{ position: 'absolute', top: 5, right: 5 }}>{props.count}</Badge></h3>
                        }
                        <CardBody>
                            <CardTitle className="mb-4 text-white">
                                <i className={"mdi " + props.icon + " me-3"}></i>{props.title}
                            </CardTitle>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>)
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>PromoCode | BrakeUp</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title={props.t("PromoCode")}
                        breadcrumbItem={props.t("PromoCode")}
                    />
                    <Row>
                        <Col xl="12">
                            <Row>
                                <Menu
                                    onclick={() => history.push('/')}
                                    color={'success'}
                                    title={'Additional Contribution'}
                                    icon="mdi-order-bool-descending"
                                    count={""}
                                />

                                <Menu
                                    onclick={() => history.push('/promocode-list')}
                                    color={'primary'}
                                    title={'PromoCode'}
                                    icon="mdi-cart-arrow-down"
                                />
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

PromoCode.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
}

export default withTranslation()(PromoCode)
