import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  DropdownItem,
  Row,
  Col,
  Table

} from "reactstrap"
import companies from "assets/images/companies"

import { Link } from 'react-router-dom';
import moment from 'moment';

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"




//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from 'react-router-dom'

const BuyerRow = props => {
  const [modal, setmodal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)
  const history = useHistory()

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }))



  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true)
    }, 2000)
  }, [])

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])


  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch]);

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y, h:mm:ss a")
    return date1
  }

  const handleProjectClick = arg => {
    const project = arg

    setProjectList({
      id: project.id,
      img: project.img,
      name: project.name,
      description: project.description,
      status: project.status,
      color: project.color,
      dueDate: project.dueDate,
      team: project.team,
    })

    setIsEdit(true)

    toggle()
  }

  const handleDeleteProject = project => {
    dispatch(onDeleteProject(project))
  }

  return (
    <React.Fragment>
      <tr className={`tr-border-${props.color} mb-2`} onClick={() => props.onClick(props.item)} style={{ marginBottom: "10px" }}>
        <Row>
          <Col lg="12">
            <Row>
              <Col lg="12">
                <h5 className="text-truncate font-size-14">
                  <Link
                    className="text-dark"
                  >
                    Order Number : {props.order_id}
                  </Link>
                </h5>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <td>
                  <p className="text-muted mb-0">
                    No of items : {props.total_items}
                  </p>
                  <p className="text-muted mb-0">
                    Date & Time : {handleValidDate(props.created_at.$date)}
                  </p>
                  <p className="text-muted mb-0">
                    Amount : {props.order_total}
                  </p>
                  <p className="text-muted mb-0">
                    Payment Mode : {props.payment_mode}
                  </p>
                  <p className="text-muted mb-0">
                    No of Sellers : {props.no_of_sellers}
                  </p>
                </td>
              </Col>
              <Col lg="4">
                <td>
                  <div className="text-muted mb-1" >
                    Buyer Name :
                    {props.billing_customer_name}
                  </div>
                  <div className="text-muted mb-1" >
                    Buyer City :
                    {props.billing_city}
                  </div>
                  <div className="text-muted mb-1" >
                    Buyer Contact : {props.billing_phone}
                  </div>
                </td>
              </Col>
              <Col lg="2">
                <div className="text-muted mb-1 p-2 bordered-div" style={{ marginTop: "14%", border: "1px solid red" }}>
                  {props.status}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </tr>
    </React.Fragment>
  )
}

BuyerRow.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(BuyerRow)
