import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes, { string } from "prop-types"
import { Link, withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  Button,
} from "reactstrap"
import classnames from "classnames"
import { isEmpty, map } from "lodash"
import Select from "react-select"

//Import Star Ratings
import StarRatings from "react-star-ratings"

// RangeSlider
import Nouislider from "nouislider-react"
import "nouislider/distribute/nouislider.css"

//Import Product Images
import { productImages } from "assets/images/product"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import data
import { discountData, productsData } from "common/data"

//Import actions
import { getProducts as onGetProducts } from "store/e-commerce/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { get, post } from "../../helpers/api_helper"
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"
import { success, error, Loader } from "../../service/alerts"

const Vehicles = props => {
  const dispatch = useDispatch()
  const { history } = props
  const [productList, setProductList] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [page, setPage] = useState(1)
  const [typeFilter, setTypeFilter] = useState("")
  // eslint-disable-next-line no-unused-vars
  const [totalPage, setTotalPage] = useState(5)

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  const [products, setProducts] = useState([])
  const getProducts = async () => {
    const result = await get("/api/v1/vehiclemake/")
    if (result.data) {
      let inputs = []
      result.data.map(items => {
        if (typeof items.make === "string") {
          inputs.push(items)
        }
      })
      setProductList(inputs)
    } else {
      setProductList([])
    }
  }
  useEffect(() => {
    getProducts()
  }, [])
  const handlePageClick = page => {
    setPage(page)
  }
  const deleteMake = id => {
    post("/api/v1/delete_make/", { id: id })
      .then(res => {
        success("able to delete")
        getProducts()
      })
      .catch(err => {
        error("sorry not able delete")
      })
  }
  const onSearch = async searchValue => {
    if (!typeFilter) {
      error("Please select the type")
      return
    }
    let url = "/api/v1/search/?q=make&active=true&type=" + typeFilter
    if (searchValue) {
      url = `${url}&s=${searchValue}`
    }
    get(url)
      .then(res => {
        setProductList(res.data)
      })
      .catch(err => {
        setProductList([])
      })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Vehicles | BrakeUp</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Commercial" breadcrumbItem="Vehicles" />
          <Row>
            <Col lg="12">
              <Row className="mb-3">
                <Col lg="7" sm="6">
                  <Form className="mt-4 mt-sm-0 float-sm-end d-flex align-items-center">
                    <div style={{ width: "135px" }}>
                      <Select
                        classNamePrefix="select"
                        name="filers"
                        options={[
                          { label: "Make", value: "make" },
                          { label: "Model", value: "model" },
                        ]}
                        onChange={e => {
                          setTypeFilter(e.value)
                        }}
                        defaultValue={{ label: "Make", value: "make" }}
                        isSearchable={false}
                      />
                    </div>
                    <div className="search-box me-2">
                      <div className="position-relative">
                        <Input
                          type="text"
                          className="form-control border-0"
                          placeholder="Search by Make / Model"
                          onChange={e => onSearch(e.target.value)}
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Form>
                </Col>
                <Col lg="5" sm="6">
                  <div className="mt-2" style={{ float: "right" }}>
                    <Button
                      color="primary"
                      onClick={() => history.push("/AddVehicles")}
                    >
                      Add NEW
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                {!isEmpty(productList) &&
                  productList.map((product, key) => (
                    <Col xl="2" sm="4" xs="6" key={"_col_" + key}>
                      <Card>
                        <CardBody
                          className="p-sm-0 cursor-pointer"
                          onClick={() =>
                            history.push(`/make-details/${product._id["$oid"]}`)
                          }
                        >
                          <div className="product-img position-relative">
                            <img
                              src={
                                product.image
                                  ? product.image[0].startsWith("https://")
                                    ? product.image[0]
                                    : `https://${product.image[0]}`
                                  : ""
                              }
                              alt=""
                              className="img-fluid mx-auto d-block"
                              style={{ height: "100px", minWidth: "100px" }}
                            />
                          </div>
                          <div className="mt-4 text-center">
                            <span className="text-muted me-2">Make Name</span>
                            <h5 className="mb-3 text-truncate">
                              <div className="text-dark">{product.make}</div>
                            </h5>
                            <span className="text-muted me-2">
                              Total Models:{product.total_models}
                            </span>
                            <br></br>
                            <span className="text-muted me-2">
                              Total Products:{product.total_products_make}
                            </span>
                          </div>
                        </CardBody>
                        <div style={{ marginBottom: "20px" }}>
                          <EditOutlined
                            style={{
                              float: "left",
                              color: "green",
                              marginLeft: "15px",
                            }}
                            onClick={() =>
                              history.push(
                                `/update-make/${product._id["$oid"]}`
                              )
                            }
                          />
                          <DeleteOutlined
                            style={{
                              float: "right",
                              color: "red",
                              marginRight: "15px",
                            }}
                            onClick={() => deleteMake(product._id.$oid)}
                          />
                        </div>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Vehicles.propTypes = {
  products: PropTypes.array,
  history: PropTypes.object,
  onGetProducts: PropTypes.func,
}

export default withRouter(Vehicles)
