import PropTypes from "prop-types"
import React, { useEffect, useState, useRef, useLayoutEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  DropdownItem,
  Row,
  Col,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Input,
  Form,
  TabContent,
  TabPane,
  NavItem,
  Nav,
  NavLink,
  Button,
} from "reactstrap"
import companies from "assets/images/companies"

import { Link } from "react-router-dom"
import moment from "moment"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"
import TableRow from "./orderRow"
import classnames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import axios from "axios"
import { get } from "../../helpers/api_helper"

import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import Select from "react-select"

import { PaginationControl } from "react-bootstrap-pagination-control"
import useLastScroll from "./useLastScroll"

const Orders = props => {
  const pageToken = JSON.parse(sessionStorage.getItem("pageToken" || {}))
  const history = useHistory()

  const [last_id, setLastId] = useState("")
  const [customActiveTab, setcustomActiveTab] = useState(
    pageToken && pageToken?.activeTab ? pageToken?.activeTab : "0"
  )
  const [search, setSearch] = useState(null)
  const [filter, setFilter] = useState([])

  // new orders

  const [newOrders, setNewOrders] = useState([])
  const [total_NewOrders, setTotal_NewOrders] = useState(0)

  // Orders pagination

  const [orders, setOrders] = useState([])
  const [total_orders, setTotal] = useState(0)
  const [page, setPage] = useState(pageToken ? pageToken?.page : 1)
  const [limit, setLimit] = useState(
    pageToken ? pageToken?.limit : { label: "10", value: 10 }
  )

  useEffect(() => {
    getNewOrders()
    getOrders(page)
  }, [])

  const getNewOrders = () => {
    get(`/api/v1/order/admin_orders/`)
      .then(response => {
        setNewOrders(response.order_list)
        setTotal_NewOrders(response.total_count)
      })
      .catch(error => {})
    setNewOrders([])
    setTotal_NewOrders(0)
  }

  const getOrders = (page, limit2) => {
    get(
      `/api/v1/order/orders/?page_size=${
        limit2?.value ?? limit?.value
      }&page_number=${page}`
    )
      .then(res => {
        setOrders(res.order_list)
        setTotal(res.total_count)
        setTimeout(() => {
          scrollOnceMove()
          // console.log(history)
        }, 100)
        // console.log(res)
        // console.log(pageToken, "pageTokenpageTokenpageToken")
      })
      .catch(error => {
        setOrders([])
        setTotal(0)
      })
  }

  // useEffect(() => {
  //   // console.log(history, 'historyhistoryhistory')
  //   if (history.location && history.location.search == "?rstatus") {
  //     setcustomActiveTab("2")
  //     getOrders("Replacement")
  //   } else {
  //     setcustomActiveTab("1")
  //     getOrders("")
  //   }
  // }, [])

  const onClickRow = item => {
    history.push(`/orders/${item.order_id}`, { order_id: item.order_id })
  }
  const onNext = () => {
    let last_item = orders[orders.length - 1]
    // console.log(last_id, last_item._id.$oid)
    // if (search == "" && filter.length == 0) {
    if (last_item && last_id != last_item._id.$oid) {
      let params = `?limit=${limit}&previousId=${last_item._id.$oid}`
      if (customActiveTab === "2") {
        params = params + `&order_status=Replacement`
      }
      get(`/api/v1/order/orders/${params}`)
        .then(res => {
          if (res.order_list) {
            let new_orders = [...orders, ...res.order_list]
            // console.log(new_orders, "dataaaa")
            setOrders(new_orders)
          }
        })
        .catch(error => {})
      setLastId(last_item._id.$oid)
    }
    // }
    // console.log(search, filter, "searchfilterrr")
  }
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      if (tab === "0") {
        getNewOrders()
      } else if (tab === "1") {
        getOrders(page)
      } else {
        // getOrders("Replacement")
      }
      setPageToken(limit, page, tab)
      setcustomActiveTab(tab)
    }
  }

  const onSearch = async searchValue => {
    // console.log(searchValue, "searchValuev")
    let url = "/api/v1/order/orders/?q=orderlist&limit=20"

    if (customActiveTab == "1") {
      if (searchValue) {
        url = `${url}&s=${searchValue}`
      }
    }

    try {
      const result = await get(url)
      if (result && Object.keys(result).length > 0) {
        if (customActiveTab == "1") {
          setOrders(result.order_list)
        }

        // console.log(result, "searchresult")
      } else {
      }
    } catch {
      setOrders([])
    }
  }

  const onSearchChange = async (searchValue, filterValue) => {
    let url = "/api/v1/search/?q=orderlist"
    if (searchValue) {
      url = `${url}&s=${searchValue}`
    }
    if (customActiveTab == "1") {
      url = `${url}&ostatus=Replacement`
    } else {
      url = `${url}&rstatus=Replacement`
    }
    if (filterValue && Object.keys(filterValue).length > 0) {
      if (filterValue.value == "Open" || filterValue.value == "Closed") {
        url = `${url}&order_status=${filterValue.value}`
      } else {
        url = `${url}&status=${filterValue.value}`
      }
    }
    setSearch(searchValue)
    setFilter(filterValue)

    try {
      const result = await get(url)
      if (result && Object.keys(result).length > 0) {
        setOrders(result.order_list)
      } else {
        setOrders([])
      }
    } catch {
      setOrders([])
    }
  }

  const setPageToken = (limit, page, activeTab) => {
    let data = {
      limit: limit,
      page: page,
      activeTab: activeTab ?? customActiveTab,
    }
    sessionStorage.setItem("pageToken", JSON.stringify(data))
  }

  const { scrollOnceMove } = useLastScroll()
  let ref = useRef()

  return (
    <React.Fragment>
      <div className="page-content" ref={ref}>
        <MetaTags>
          <title>Orders | BrakeUp</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Orders")}
            breadcrumbItem={props.t(`Orders(${total_orders})`)}
          />
          <Row className="mb-3">
            <Col lg="7" sm="6">
              <Form className="">
                <div className="search-box me-2">
                  <div className="position-relative">
                    <Input
                      type="text"
                      className="form-control border-0"
                      placeholder="Search by Buyer/Seller/Order/Product/Phone number/Vehicle"
                      // onChange={e => onSearchChange(e.target.value, filter)}
                      onChange={e => {
                        onSearch(e.target.value)
                        setSearch(e.target.value)
                      }}
                    />
                    <i className="bx bx-search-alt search-icon" />
                  </div>
                </div>
              </Form>
            </Col>
            <Col lg="5" sm="6">
              <Select
                style={{ width: "200px", float: "right" }}
                classNamePrefix="select"
                name="filers"
                placeholder="Filters"
                options={[
                  { label: "New Order", value: "New Order" },
                  { label: "Invoiced", value: "Invoiced" },
                  { label: "In transit", value: "IN TRANSIT" },
                  { label: "delivered", value: "DELIVERED" },
                  { label: "Return in transit", value: "Return in Transit" },
                  { label: "Return delivered", value: "Return Delivered" },
                  { label: "Rep.New Order", value: "Rep.New order" },
                  { label: "Rep.Packed", value: "Rep.Packed" },
                  { label: "Rep.In transit", value: "Rep.In Transit" },
                  { label: "Rep.Delivered", value: "Rep.Delivered" },
                  {
                    label: "Rep.Return in transit",
                    value: "Rep.Return In Transit",
                  },
                  {
                    label: "Rep.Return delivered",
                    value: "Rep.Return Delivered",
                  },
                  { label: "Closed", value: "Closed" },
                  { label: "Open", value: "Open" },
                ]}
                onChange={e => {
                  // onSearchChange(search, e)
                }}
              />
            </Col>
          </Row>
          <div className="">
            <Nav tabs className="nav-tabs-custom nav-justified">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "0",
                  })}
                  onClick={() => {
                    toggleCustom("0")
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-shopping-bag">({total_NewOrders})</i>
                  </span>
                  <span className="d-none d-sm-block">
                    New({total_NewOrders})
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "1",
                  })}
                  onClick={() => {
                    toggleCustom("1")
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-shopping-cart"></i>
                  </span>
                  <span className="d-none d-sm-block">All</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "2",
                  })}
                  onClick={() => {
                    toggleCustom("2")
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-reply-all"></i>
                  </span>
                  <span className="d-none d-sm-block">Replacement</span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={customActiveTab} className="p-3 text-muted">
              <TabPane tabId="0">
                <Row>
                  <Col sm="12">
                    <div className="table-responsive">
                      <Table className="project-list-table table-nowrap align-middle  table">
                        <tbody>
                          {newOrders &&
                            newOrders.map((item, index) => (
                              <>
                                <TableRow
                                  {...item}
                                  onClick={() => onClickRow(item)}
                                  color={"blue"}
                                />
                                <br />
                              </>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="1">
                {customActiveTab == 1 && search ? (
                  <Row>
                    <Col sm="12">
                      <div className="table-responsive">
                        <Table className="project-list-table table-nowrap align-middle  table">
                          <tbody>
                            {orders &&
                              orders.map((item, index) => (
                                <>
                                  <TableRow
                                    {...item}
                                    onClick={() => onClickRow(item)}
                                    color={
                                      item.status == "Closed"
                                        ? "green"
                                        : item.status == "New Order"
                                        ? "blue"
                                        : "red"
                                    }
                                  />
                                  <br />
                                </>
                              ))}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row style={{ position: "relative" }}>
                      <Col sm="12">
                        {total_orders && total_orders > 10 && (
                          <Row
                            className="sm-relative position-absolute top-0 mb-2"
                            // style={{ width: "100px" }}
                          >
                            <div style={{ width: "100px", float: "left" }}>
                              <Select
                                classNamePrefix="select"
                                name={"limit"}
                                options={[
                                  { label: "10", value: 10 },
                                  { label: "30", value: 30 },
                                  { label: "60", value: 60 },
                                  { label: "100", value: 100 },
                                ]}
                                components={{
                                  IndicatorSeparator: () => null,
                                }}
                                value={limit}
                                defaultValue={limit}
                                onChange={e => {
                                  setLimit(e)
                                  getOrders(1, e)
                                  setPage(1)
                                  setPageToken(e, 1)
                                }}
                              />
                            </div>
                          </Row>
                        )}
                        <PaginationControl
                          page={page}
                          between={4}
                          total={total_orders}
                          limit={limit?.value}
                          changePage={page => {
                            setPage(page)
                            getOrders(page)
                            setPageToken(limit, page)
                          }}
                          ellipsis={2}
                        />

                        <div className="table-responsive">
                          <Table className="project-list-table table-nowrap align-middle  table">
                            <tbody>
                              {orders &&
                                orders.map((item, index) => (
                                  <>
                                    <TableRow
                                      {...item}
                                      onClick={() => onClickRow(item)}
                                      color={
                                        item.status == "Closed"
                                          ? "green"
                                          : item.status == "New Order"
                                          ? "blue"
                                          : "red"
                                      }
                                    />
                                    <br />
                                  </>
                                ))}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>

                    {orders && orders.length > 8 && (
                      <Row style={{ position: "relative" }}>
                        <Col sm="12">
                          <PaginationControl
                            page={page}
                            between={4}
                            total={total_orders}
                            limit={limit?.value}
                            changePage={page => {
                              setPage(page)
                              getOrders(page)
                              window.scrollTo(0, 0)
                            }}
                            ellipsis={2}
                          />
                        </Col>
                      </Row>
                    )}
                  </>
                )}
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    <div className="table-responsive">
                      <Table className="project-list-table table-nowrap align-middle  table">
                        <tbody>
                          {/* {orders &&
                            orders.map((item, index) => (
                              <>
                                <TableRow
                                  {...item}
                                  onClick={() => onClickRow(item)}
                                  color={
                                    item.status == "Closed" ? "green" : "red"
                                  }
                                />
                                <br />
                              </>
                            ))} */}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

Orders.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Orders)
