import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Col, Card, CardBody, CardTitle, Badge } from "reactstrap"
import { withTranslation } from "react-i18next"

const Menu = props => {
  return (
    <React.Fragment>
      <Col onClick={props.onclick} xl={2} lg={3} md={4} sm={6}>
        <Card color={props.color} className="cursor-pointer text-white-50 h-90">
          {props.count && (
            <h3>
              <Badge
                color="secondary"
                style={{ position: "absolute", top: 5, right: 5 }}
              >
                {props.count}
              </Badge>
            </h3>
          )}

          <CardBody>
            <CardTitle className="mb-4 text-white font-size-16 text-uppercase">
              <i className={props.icon + " me-3"}></i>
              {props.title}
            </CardTitle>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

Menu.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Menu)
