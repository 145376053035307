import { message } from 'antd';
import { Spin, Alert } from 'antd';

message.config({ 
  maxCount: 1, 
});


export const success = (info) => {
    message.success(info, 1);
  };
  
export const error = (info) => {
  message.error(info, 2); 
  
};

export const Loader = () =>{
  return(
  <div style={{
    position:"absolute",
    width:"76%",
    height:"100%"
  }}>
    <Spin tip="Loading..." className="loading" style={{
      display:"block",
      marginLeft: "auto",
      marginRight:"auto"
    }}/>
  </div>)
}
