import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
} from "reactstrap"

import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

//redux

const TopCard = props => {
  const history = useHistory()

  return (
    <React.Fragment>
      <Col>
        <Card
          className="mini-stats-wid"
          onClick={() => history.push(props.url)}
        >
          <CardBody>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <p className="text-muted fw-medium mb-0">{props.title}</p>
                {props?.day != "" && (
                  <h3 className="mb-0 pt-1" style={{ height: "38px" }}>
                    {props.day}
                  </h3>
                )}
                {props?.day == "" ? (
                  <h2 className="mb-2 mt-3">{props.description}</h2>
                ) : (
                  <h5 className="mb-0">{props.description}</h5>
                )}
              </div>

              <div
                className="avatar-sm rounded-circle  align-self-center mini-stat-icon "
                style={{ background: "#556ee6", opacity: "70%" }}
              >
                <span
                  className="avatar-title rounded-circle "
                  style={props?.bg ? props.bg : { background: "#556ee6" }}
                >
                  <i className={"bx " + props.iconClass + " font-size-24"}></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

TopCard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(TopCard)
