import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import classnames from "classnames"
import { isEmpty } from "lodash"
import "nouislider/distribute/nouislider.css"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
//redux
import { get, post } from "../../helpers/api_helper";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { success, error, Loader } from "../../service/alerts";

const VehiclesVersions = props => {
  const { history } = props
  const [productList, setProductList] = useState([]);
  const [versions, setVersions] = useState([]);
  const [activeTab, setActiveTab] = useState("1")
  // eslint-disable-next-line no-unused-vars

  const [modal, setmodal] = useState(false)
  const [deleteID, setDeleteID] = useState({});



  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  const [products, setProducts] = useState([]);
  const {
    match: { params },
  } = props
  const getProducts = async () => {
    const result = await get(`/api/v1/vehiclemodel/?model_id=${params.id}`);
    // console.log(result, 'resultresultresultresult')
    if (result.data) {
      setProductList(result.data)
    } else {
      setProductList([]);
    }
    const results = await get(`/api/v1/add_vehicle_details/?id=${params.id}`);
    if (results.data) {
      setVersions(results.data.data)
    } else {
      setVersions([]);
    }
  }
  useEffect(() => {
    getProducts();
  }, [])
  const handlePageClick = page => {
    setPage(page)
  }
  const onSearch = async (searchValue) => {
    let url = "/api/v1/search/?q=version&active=true&model_id=" + params.id
    if (searchValue) {
      url = `${url}&s=${searchValue}`
    }
    get(url).then(res => {
      setVersions(res[0]);
    }).catch(err => {
      setVersions([]);
    })
  }
  const deleteModel = (id) => {
    // post("/api/v1/delete_model/", { id: id }).then(res => {
    //   success("able to delete");
    //   getProducts();
    // }).catch(err => {
    //   error("sorry not able delete");
    // })
    // console.log(id, "deletemodelllllll");
  }
  const deleteVersion = (id) => {
    // post("/api/v1/delete_version/", { id: id }).then(res => {
    //   success("able to delete");
    //   getProducts();
    // }).catch(err => {
    //   error("sorry not able delete");
    // })
  }
  const deleteModelVersion = (id, key) => {
    setmodal(!modal)
    if (key == "model") {
      post("/api/v1/delete_model/", { id: id }).then(res => {
        success("Able to delete model");
        getProducts();
      }).catch(err => {
        error("Sorry, not able delete");
      })
    } else {
      post("/api/v1/delete_version/", { id: id }).then(res => {
        success("Able to delete verison");
        getProducts();
      }).catch(err => {
        error("Sorry, not able delete");
      })
    }
    setDeleteID({})
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Vehicles |  BrakeUp</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Commercial" breadcrumbItem="Models" />
          <Row>
            <Col lg="12">
              <Row className="mb-3">
                <Col xl="4" sm="6">

                </Col>
                <Col lg="8" sm="6">
                  <Form className="mt-4 mt-sm-0 float-sm-end d-flex align-items-center">
                    <div className="search-box me-2">
                      <div className="position-relative">
                        <Input
                          type="text"
                          className="form-control border-0"
                          placeholder="Search..."
                          onChange={(e) => onSearch(e.target.value)}
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                    {/* <Nav className="product-view-nav" pills>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "1",
                          })}
                          onClick={() => {
                            toggleTab("1")
                          }}
                        >
                          <i className="bx bx-grid-alt" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "2",
                          })}
                          onClick={() => {
                            toggleTab("2")
                          }}
                        >
                          <i className="bx bx-list-ul" />
                        </NavLink>
                      </NavItem>
                    </Nav> */}
                  </Form>
                </Col>
              </Row>
              <Row>
                {!isEmpty(productList) &&
                  productList.map((product, key) => (
                    <Col xl="12" sm="12" key={"_col_" + key}>
                      <Card

                      >
                        <CardBody
                        >
                          <Row>
                            <Col xl="1" sm="1">
                              {/* <div className="product-img position-relative">
                                <img
                                  src={product.image ? product.image[0] && product.image[0].startsWith("https://") ? product.image[0] :
                                    `https://${product.image[0]}` : ""

                                  }
                                  alt=""
                                  className="img-fluid mx-auto d-block"
                                  style={{ height: "50px" }}
                                />
                              </div> */}
                            </Col>

                            <Col xl="5" sm="6">
                              <div className="mt-4 ">
                                <h5 className="mb-3 text-truncate">
                                  MODEL NAME: {product.model}
                                </h5>
                                <span className="text-muted me-2">
                                  Total Versions: {product.total_versions}
                                </span>
                                <br/>
                              </div>
                              <div style={{ marginBottom: "20px", marginTop: "10px" }}>
                                <EditOutlined style={{ marginRight: "20px", color: "green", marginLeft: "15px" }}
                                  onClick={() =>
                                    history.push(
                                      `/updateModels/${product._id["$oid"]}`
                                    )
                                  }
                                />
                                <DeleteOutlined style={{ color: "red", marginRight: "15px" }}
                                  // onClick={() => deleteModal(product._id.$oid)} 
                                  onClick={() => {
                                    setmodal(!modal)
                                    setDeleteID({ id: product._id.$oid, key: "model" })
                                  }} />
                              </div>
                            </Col>
                            <Col xl="4" sm="5">
                              <div className="mt-4 ">
                                <button className="btn btn-outline-secondary mb-3 text-truncate cursor-pointer" onClick={() => {
                                  history.push(`/get-versionsByProducts/${params.id}`)
                                }}>
                                  VIEW PRODUCTS ({product.total_products_model})
                                </button>
                                <br></br>
                                {/* <div className="text-muted me-2">
                                  Total Products: {product.total_products_model}
                                </div><br></br> */}
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
              </Row>
              <Row>
                {!isEmpty(versions) &&
                  versions.map((product, key) => (
                    <Col xl="12" sm="12" key={"_col_" + key}>
                      <Card
                      >
                        <CardBody>
                          <Row>
                            {/* <div >
                              <span className="text-muted me-2" style={{ float: "right" }}>
                                SL No: {product.sl_no}
                              </span>
                            </div> */}

                            <div>

                              <DeleteOutlined style={{ float: "right", color: "red", marginRight: "15px" }}
                                //  onClick={() => deleteMake(product._id.$oid)}
                                onClick={() => {
                                  setmodal(!modal)
                                  setDeleteID({ id: product._id.$oid, key: "version" })
                                }} />
                              <EditOutlined style={{ float: "right", color: "green", marginRight: "45px" }}
                                onClick={() =>
                                  history.push(
                                    `/UpdateVehicles/${product._id["$oid"]}`
                                  )
                                }
                              />
                            </div>
                            <Col xl="3" sm="4">
                              <div className="product-img position-relative">
                                <h5 className="mb-3 text-truncate">
                                  <Link
                                    className="text-dark"
                                  >
                                    {product.version}
                                  </Link>
                                </h5>
                                <img
                                  src={product.image ? product.image[0] && product.image[0].startsWith("https://") ? product.image[0] :
                                    `https://${product.image[0]}` : ""

                                  }
                                  alt=""
                                  className="img-fluid mx-auto d-block"
                                  style={{ height: "30px" }}
                                />

                              </div>
                            </Col>
                            <Col xl="8" sm="8">
                              <Row xs='2' >
                                {/* <Col xl="6">
                                  <div className="mt-4 ">
                                    <span className="text-muted me-2">
                                      Chassis Code: {product.chassis_code}
                                    </span>
                                  </div>
                                </Col> */}
                                <Col>
                                  <div className="mt-4 ">
                                    <span className="text-muted me-2">
                                      Total Products: {product.total_version_products}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                              <Row xs='4'>
                                <Col xs='4' sm="4" xl="4">
                                  <div className="mt-4 ">
                                    <span className="text-muted me-2">
                                      Fuel Type: {product.fuel_type}
                                    </span>
                                  </div>
                                </Col>
                                <Col xs='4' sm="4" xl="4">
                                  <div className="mt-4 ">
                                    <span className="text-muted me-2">
                                      From Year: {product.from_year}
                                    </span>
                                  </div>
                                </Col>
                                <Col xs='4' sm="4" xl="4">
                                  <div className="mt-4 ">
                                    <span className="text-muted me-2">
                                      To Year: {product.isPresent ? new Date().getFullYear() : product.to_year}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </CardBody>

                      </Card>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
        </Container>

        <Modal
          size="sm"
          isOpen={modal}
          toggle={() => {
            setmodal(!modal)
          }}
        >
          <ModalHeader
            toggle={() => {
              setmodal(!modal)
            }}
          >
            Delete {deleteID.key} ?
          </ModalHeader>
          <ModalBody>
            <Row>
              <div className="mb-3">
                <label htmlFor="name">Are you sure  want to delete</label>
              </div>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="d-flex justify-content-end">
                  <div className="">
                    <button type="cancel" className="btn btn-secondary"
                      onClick={() => {
                        setmodal(!modal)
                        setDeleteID({})
                      }}>
                      Cancel
                    </button>
                  </div>
                  <div className="">
                    <button type="submit" className="btn btn-primary ms-2"
                      onClick={() => {
                        deleteModelVersion(deleteID.id, deleteID.key)
                      }}>
                      Submit
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

      </div>
    </React.Fragment>
  )
}

VehiclesVersions.propTypes = {
  products: PropTypes.array,
  history: PropTypes.object,
  onGetProducts: PropTypes.func,
}

export default withRouter(VehiclesVersions)
