import React, { useState } from "react"
import MetaTags from 'react-meta-tags';

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  Table,
} from "reactstrap"
import Dropzone from "react-dropzone"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { CustomInput, CustomSelect } from "../Ecommerce/customInputs";
import { post, get } from "../../helpers/api_helper";
import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import AddAddress from "./addAddress";
import { success, error, Loader } from "../../service/alerts";

import { API_URL } from "../../helpers/api_helper";


const AddCompany = (props) => {


  const [showOther, setShowOther] = useState(false);
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [firstForm, setFirstForm] = useState({});
  const [secondForm, setSecondForm] = useState({});
  const [ifEdit, setIfEdit] = useState(false);

  const history = useHistory();
  let { id } = useParams();


  useEffect(() => {

    setLoader(true);
    // get(`/api/v1/seller/seller/details/?id=${id}`).then(res => {
    get(`/api/v1/seller/sellerprofile/?user_id=${id}`).then(res => {
      setIfEdit(true)
      console.log(res, 'resresresres')
      let firstForm = {
        authorized_person: res.authorized_person,
        company_logo: res.company_logo,
        pickup_location: res.pickuplocation,
        company_name: res.company_name,
        display_name: res.display_name,
        pickup_address: res.pickupaddress,
        reg_address: res.reg_address,
        user_name: res.user_name,
        mobile: res.user_detail[0][0].mobile,
        email: res.user_detail[0][0].email

      }
      let secondForm = {
        GST_type: res.GST_type,
        PAN: res.PAN,
        bank_IFSC: res.bank_IFSC,
        bank_accno: res.bank_accno,
        bank_branch: res.bank_branch,
        bank_name: res.bank_name,
        GST_number: res.GST_number

      }
      setFirstForm(firstForm);
      setSecondForm(secondForm);
      setLoader(false);

    }).catch(err => {
      setLoader(false);
    })

  }, [id]);

  const FirstForm = (props) => {
    const { handleSubmit, errors, control, setValue, watch, register, unregister } = useForm({
      mode: 'onChange'
    });
    const [selectedFiles, setselectedFiles] = useState([]);
    const [address, setAddress] = useState([]);

    const handleAcceptedFiles = (acceptedFiles) => {
      let formData = new FormData();
      formData.append("name", acceptedFiles[0].name);
      formData.append("type", "product");
      formData.append("file", acceptedFiles[0]);
      fetch( API_URL + `/api/v1/products/uploadimage/`,
        {
          method: 'POST',
          body: formData
        }
      )
        .then(response => response.json())
        .then(res => {
          let selected = [];
          selected.push(`${res.url}`);
          setselectedFiles(selected);
        })
    }
    useEffect(() => {
      if (props && Object.keys(props.firstForm).length > 0) {
        setValue("authorized_person", props.firstForm.authorized_person)
        setselectedFiles([props.firstForm.company_logo]);
        setAddress(props.firstForm.pickup_location);
        setValue("company_name", props.firstForm.company_name)
        setValue("confirm_password", props.firstForm.confirm_password)
        setValue("display_name", props.firstForm.display_name)
        setValue("email", props.firstForm.email)
        setValue("mobile", props.firstForm.mobile)
        setValue("password", props.firstForm.password)
        setValue("pickup_address", props.firstForm.pickup_address)
        setValue("reg_address", props.firstForm.reg_address)
        setValue("user_name", props.firstForm.user_name)
      }
    }, [props.firstForm])
    const onSubmit = async (items) => {
      if (items.password != items.confirm_password) {
        error("password not matching with confirm password");
        return;
      }
      if (selectedFiles.length == 0) {
        error("Please add Company logo");
        return;
      }
      items.company_logo = selectedFiles.length > 0 ? selectedFiles[0] : "";
      items.pickup_location = [...address];
      // const token = JSON.parse(localStorage.getItem("adminToken"));
      // items.user_id = token.id;
      props.getValuesFromForm1(items)
    }
    const addressDetails = (values) => {
      let obj = [...address];
      obj.push(values);
      obj.length == 1 ? obj[0].default = 1 : ""
      setAddress(obj);
    }

    const DefaultAddress = (selectedindex) => {
      let newaddress = [...address];
      newaddress.map((items, index) => {
        if (selectedindex == index) {
          items.default = 1
        } else {
          items.default = 0
        }
      }
      )
      setAddress(newaddress);
    }

    const validatePhone = (number) => {
      // Phone number validations
      if (isNaN(number)) {
        return String(number)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      } else {
        var re = /^[0][1-9]\d{9}$|^[1-9]\d{9,10}$/g;
        return re.test(number);
      }

    };
    return (<Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col lg={6}>
                  <div className="row mb-4">
                    <Col lg={6}>
                      <Dropzone
                        onDrop={acceptedFiles => {
                          handleAcceptedFiles(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps()}
                            style={{
                              height: "160px",
                              border: "1px dashed #60e0ff",
                              textAlign: "center",
                              cursor: "pointer"
                            }}
                          >
                            <input {...getInputProps()} />
                            {selectedFiles.length == 0 && <h4 style={{ marginTop: "64px" }}>Company Logo</h4>}
                            {selectedFiles.map((f, i) => {
                              return (
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  style={{ width: "100%", height: "100%", objectFit: 'contain' }}
                                  alt={"image"}
                                  src={f}
                                />)
                            })
                            }
                          </div>
                        )}
                      </Dropzone>
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label
                      htmlFor="horizontal-firstname-Input"
                      className="col-sm-3 col-form-label"
                    >
                      Phone Number
                    </Label>
                    <Col sm={9}>
                      <Controller
                        as={<CustomInput />}
                        defaultValue={null}
                        name="mobile"
                        onChange={([e]) => {
                          return { value: e };
                        }}
                        control={control}
                        type={"text"}
                        rules={{ required: true, validate: validatePhone }}
                      />
                      {errors.mobile &&
                        errors.mobile.type == "required" &&
                        <span style={{ color: "red" }}>Phone number is required.</span>}
                      {errors.mobile &&
                        errors.mobile.type == "validate" &&
                        <span style={{ color: "red" }}>Phone number is not valid.</span>}
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label
                      htmlFor="horizontal-email-Input"
                      className="col-sm-3 col-form-label"
                    >
                      Email
                    </Label>
                    <Col sm={9}>
                      <Controller
                        as={<CustomInput />}
                        defaultValue={null}
                        name="email"
                        onChange={([e]) => {
                          return { value: e };
                        }}
                        control={control}
                        type={"email"}
                        rules={{ required: true }}
                      />
                      {errors.email && <span style={{ color: "red" }}>Email is required.</span>}
                    </Col>
                  </div>
                  {/* <div className="row mb-4">
                    <Label
                      htmlFor="horizontal-email-Input"
                      className="col-sm-3 col-form-label"
                    >
                      User ID
                    </Label>
                    <Col sm={9}>
                      <Controller
                        as={<CustomInput />}
                        defaultValue={null}
                        name="user_name"
                        onChange={([e]) => {
                          return { value: e };
                        }}
                        control={control}
                        type={"text"}
                        rules={{ required: true }}
                      />
                      {errors.user_name && <span style={{ color: "red" }}>use name is required.</span>}
                    </Col>
                  </div> */}

                  {ifEdit ? "" : <>
                    <div className="row mb-4">
                      <Label
                        htmlFor="horizontal-password-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Password
                      </Label>
                      <Col sm={9}>
                        <Controller
                          as={<CustomInput />}
                          defaultValue={null}
                          name="password"
                          onChange={([e]) => {
                            return { value: e };
                          }}
                          control={control}
                          type={"password"}
                          rules={{ required: true }}
                        />
                        {errors.password && <span style={{ color: "red" }}>password is required.</span>}
                      </Col>
                    </div>
                    <div className="row mb-4">
                      <Label
                        htmlFor="horizontal-password-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Confirm Password
                      </Label>
                      <Col sm={9}>
                        <Controller
                          as={<CustomInput />}
                          defaultValue={null}
                          name="confirm_password"
                          onChange={([e]) => {
                            return { value: e };
                          }}
                          control={control}
                          type={"password"}
                          rules={{ required: true }}
                        />
                        {errors.confirm_password && <span style={{ color: "red" }}>password is required.</span>}
                      </Col>
                    </div>
                  </>
                  }
                </Col>
                <Col lg={6}>
                  <div className="row mb-4">
                    <Label
                      htmlFor="horizontal-firstname-Input"
                      className="col-sm-3 col-form-label"
                    >
                      Company Name
                    </Label>
                    <Col sm={9}>
                      <Controller
                        as={<CustomInput />}
                        defaultValue={null}
                        name="company_name"
                        onChange={([e]) => {
                          return { value: e };
                        }}
                        control={control}
                        type={"text"}
                        rules={{ required: true }}
                      />
                      {errors.company_name && <span style={{ color: "red" }}>Company name is required.</span>}

                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label
                      htmlFor="horizontal-firstname-Input"
                      className="col-sm-3 col-form-label"
                    >
                      Display Name
                    </Label>
                    <Col sm={9}>
                      <Controller
                        as={<CustomInput />}
                        defaultValue={null}
                        name="display_name"
                        onChange={([e]) => {
                          return { value: e };
                        }}
                        control={control}
                        type={"text"}
                        rules={{ required: true }}
                      />
                      {errors.display_name && <span style={{ color: "red" }}>Display name is required.</span>}

                    </Col>
                  </div><div className="row mb-4">
                    <Label
                      htmlFor="horizontal-firstname-Input"
                      className="col-sm-3 col-form-label"
                    >
                      Authorized Person
                    </Label>
                    <Col sm={9}>
                      <Controller
                        as={<CustomInput />}
                        defaultValue={null}
                        name="authorized_person"
                        onChange={([e]) => {
                          return { value: e };
                        }}
                        control={control}
                        type={"text"}
                        rules={{ required: true }}
                      />
                      {errors.authorized_person && <span style={{ color: "red" }}>Authorized person is required.</span>}

                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label
                      htmlFor="horizontal-firstname-Input"
                      className="col-sm-3 col-form-label"
                    >
                      Registered Address
                    </Label>
                    <Col sm={9}>
                      <Controller
                        as={<CustomInput />}
                        defaultValue={null}
                        name="reg_address"
                        onChange={([e]) => {
                          return { value: e };
                        }}
                        control={control}
                        type={"textarea"}
                        rules={{ required: true }}
                      />
                      {errors.reg_address && <span style={{ color: "red" }}>Reg address is required.</span>}

                    </Col>
                  </div>
                  <div className="row mb-4">
                    <AddAddress addressDetails={addressDetails} />


                  </div>
                </Col>
                <Col lg="12" style={{ marginTop: "-37px" }}>
                  <div className="row mb-4">
                    <Col sm={3}></Col>
                    <Col sm={9}>
                      <div className="mt-5">
                        <div className="table-responsive">
                          <Table className="table mb-0 table-bordered">
                            <tbody>
                              <tr key="header">
                                <th></th>
                                <th>
                                  Pickup location
                                </th>
                                <th>Address 1</th>
                                <th>Address 2</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Country</th>
                                <th>Pin Code</th>
                                <th>Action</th>
                              </tr>
                              {address &&
                                address.map((items, i) => {
                                  return (
                                    <tr key={i}>
                                      <td style={{ textAlign: "center", width: "3vw" }}>
                                        <input type="radio" name="defaultAddress"
                                          checked={items.default == 1 ? true : false
                                          }
                                          onChange={() => { DefaultAddress(i) }}
                                        />
                                      </td>
                                      <td>
                                        {items.pickup_location}
                                      </td>
                                      <td>{items.address}</td>
                                      <td>{items.address_2}</td>
                                      <td>{items.city}</td>
                                      <td>{items.state}</td>
                                      <td>{items.country}</td>
                                      <td>{items.pincode}</td>
                                      <td style={{ cursor: "pointer", color: "#b63333" }} onClick={() => {
                                        let values = [...address];
                                        values.splice(i, 1);
                                        setAddress(values);
                                      }}>Remove</td>
                                    </tr>
                                  )
                                })}
                            </tbody>
                          </Table>
                        </div>
                      </div>

                      <Button
                        type="submit"
                        color="primary"
                        className="w-md"
                        style={{ float: "right", marginTop: "15px" }}
                      >
                        Save and Continue
                      </Button>

                      <Button
                        onClick={() => history.goBack()}
                        color="secondary"
                        className="w-md"
                        style={{ float: "right", marginTop: "15px", marginRight: "15px" }}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </div>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
    )
  }
  const SecondForm = (props) => {
    const [gstType, setGstType] = useState("Regular");
    const { handleSubmit, errors, control, setValue, watch, register, unregister } = useForm({
      mode: 'onChange'
    });
    useEffect(() => {
      if (props.secondForm) {
        setGstType(props.secondForm.GST_type);
        setValue("PAN", props.secondForm.PAN);
        setValue("bank_IFSC", props.secondForm.bank_IFSC);
        setValue("bank_accno", props.secondForm.bank_accno);
        setValue("bank_branch", props.secondForm.bank_branch);
        setValue("bank_name", props.secondForm.bank_name);
        setValue("GST_number", props.secondForm.GST_number);
      }

    }, [props.secondForm])
    const onSubmit = (values) => {
      values.GST_type = gstType;
      getValuesFromForm2(values, ifEdit ? "edit" : "add");
    }
    const validatePan = (number) => {
      // Phone number validations
      if (number && number.length == 10) {
        return true
      }
      return false;
    };
    const validateGst = (number) => {
      // Phone number validations
      if (number && number.length == 15) {
        return true
      }
      return false;
    };
    const validateIFSC = (number) => {
      // Phone number validations
      if (number && number.length == 11) {
        return true
      }
      return false;
    };
    return (<Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardBody>
          <CardTitle>
            <div className="d-flex justify-content-between mb-5" >
              {ifEdit ? <div></div> :
                <div>{firstForm.company_name}</div>}
              <Button color="link" onClick={() => setShowOther(false)}>Back</Button>
            </div>
          </CardTitle>
          <div className="row mb-4">
            <Label
              htmlFor="horizontal-firstname-Input"
              className="col-sm-3 col-form-label"
            >
              PAN Number
            </Label>
            <Col sm={9}>
              <Controller
                as={<CustomInput />}
                defaultValue={null}
                name="PAN"
                onChange={([e]) => {
                  return { value: e };
                }}
                control={control}
                rules={{ required: true, validate: validatePan }}
                type={"text"}
              />
              {errors.PAN &&
                errors.PAN.type &&
                errors.PAN.type == "required"
                ?
                <span style={{ color: "red" }}>PAN number is required.</span> : ""}
              {errors.PAN &&
                errors.PAN.type &&
                errors.PAN.type == "validate"
                ?
                <span style={{ color: "red" }}>Ten digits are allowed</span> : ""}

            </Col>
          </div>
          <div className="row mb-4">
            <Label
              htmlFor="horizontal-firstname-Input"
              className="col-sm-3 col-form-label"
            >
              GST Details
            </Label>
            <Col sm={9}>
              <Controller
                as={<CustomInput />}
                defaultValue={null}
                name="GST_number"
                onChange={([e]) => {
                  return { value: e };
                }}
                control={control}
                rules={{ required: true, validate: validateGst }}
                type={"text"}
              />
              {errors.GST_number &&
                errors.GST_number.type &&
                errors.GST_number.type == "required"
                ?
                <span style={{ color: "red" }}>GST details is required.</span> : ""}
              {errors.GST_number &&
                errors.GST_number.type &&
                errors.GST_number.type == "validate"
                ?
                <span style={{ color: "red" }}>15 digits are allowed</span> : ""}

            </Col>
          </div>
          <div className="mt-4">
            <Row>
              <Col lg={6}>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    value="Regular"
                    checked={gstType == 'Regular'}
                    onClick={() => setGstType('Regular')}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="exampleRadios1"
                  >
                    Regular
                  </label>
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios2"
                    value="Composition"
                    checked={gstType == 'Composition'}
                    onClick={() => setGstType('Composition')}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="exampleRadios2"
                  >
                    Composition
                  </label>
                </div>
              </Col>
            </Row>
          </div>
          <div className="row mb-4">
            <Label
              htmlFor="horizontal-firstname-Input"
              className="col-sm-3 col-form-label"
            >
              BANK Account Number
            </Label>
            <Col sm={9}>
              <Controller
                as={<CustomInput />}
                defaultValue={null}
                name="bank_accno"
                onChange={([e]) => {
                  return { value: e };
                }}
                control={control}
                rules={{ required: true }}
                type={"text"}
              />
              {errors.bank_accno && <span style={{ color: "red" }}>Bank Account number is required.</span>}

            </Col>
          </div>
          <div className="row mb-4">
            <Label
              htmlFor="horizontal-firstname-Input"
              className="col-sm-3 col-form-label"
            >
              BANK Name
            </Label>
            <Col sm={9}>
              <Controller
                as={<CustomInput />}
                defaultValue={null}
                name="bank_name"
                onChange={([e]) => {
                  return { value: e };
                }}
                control={control}
                rules={{ required: true }}
                type={"text"}
              />
              {errors.bank_name && <span style={{ color: "red" }}>Bank name is required.</span>}

            </Col>
          </div>
          <div className="row mb-4">
            <Label
              htmlFor="horizontal-firstname-Input"
              className="col-sm-3 col-form-label"
            >
              Branch Name
            </Label>
            <Col sm={9}>
              <Controller
                as={<CustomInput />}
                defaultValue={null}
                name="bank_branch"
                onChange={([e]) => {
                  return { value: e };
                }}
                control={control}
                rules={{ required: true }}
                type={"text"}
              />
              {errors.bank_branch && <span style={{ color: "red" }}>bank branch is required.</span>}
            </Col>
          </div>
          <div className="row mb-4">
            <Label
              htmlFor="horizontal-firstname-Input"
              className="col-sm-3 col-form-label"
            >
              IFSC
            </Label>
            <Col sm={9}>
              <Controller
                as={<CustomInput />}
                defaultValue={null}
                name="bank_IFSC"
                onChange={([e]) => {
                  return { value: e };
                }}
                control={control}
                rules={{ required: true, validate: validateIFSC }}
                type={"text"}
              />
              {errors.bank_IFSC &&
                errors.bank_IFSC.type &&
                errors.bank_IFSC.type == "required"
                ?
                <span style={{ color: "red" }}>IFSC is required.</span> : ""}
              {errors.bank_IFSC &&
                errors.bank_IFSC.type &&
                errors.bank_IFSC.type == "validate"
                ?
                <span style={{ color: "red" }}>11 digits are allowed</span> : ""}

            </Col>
          </div>
          <div className="row mb-4">
            <Col sm={3}></Col>
            <Col sm={9}>
              <Button
                type="submit"
                color="primary"
                className="w-md"
                style={{ float: "right" }}
              >
                {ifEdit ? "Save edit" : "Register"}
              </Button>
            </Col>
          </div>
        </CardBody>
      </form>
    </Card>
    )
  }
  const getValuesFromForm1 = (values) => {
    setFirstForm(values);
    setShowOther(true);
  }
  const getValuesFromForm2 = (values, type) => {
    setSecondForm(values);
    const id = history.location.pathname.split("/").reverse()[0];
    const obj3 = Object.assign({}, firstForm, values);
    obj3.bank_name = obj3.bank_name;
    obj3.mobile = parseInt(obj3.mobile);
    obj3.pickup_location && obj3.pickup_location.map((items, index) => {
      items.pincode = parseInt(items.pincode);
      // items.default = (index + 1);
    })

    if (type == "add") {
      obj3.type = "Admin";
      setLoader(true);
      post("/api/v1/user/new_seller/", obj3)
        .then(res => {
          success("Seller created successfully");
          history.push("/seller");
          setLoader(false);
        }).catch(err => {
          error(err?.response?.data?.message);
          setLoader(false);
        })
    } else if (type == "edit") {
      obj3.user_id = id;
      obj3.type = "Seller";
      setLoader(true);
      post("/api/v1/seller_update/", obj3)
        .then(res => {
          success("Seller Updated successfully");
          history.push(`/seller-details/${res.vend_id}`);
          setLoader(false);
        }).catch(err => {
          error("Sorry not able update Seller");
          setLoader(false);
        })

    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Company Details |  Brakeup</title>
        </MetaTags>
        <Container fluid={true}>
          {loader && <Loader />}
          <Breadcrumbs title="Company" breadcrumbItem={ifEdit ? `EDIT ${firstForm.company_name}` : "Add Company"} />
          {showOther === false &&
            <FirstForm getValuesFromForm1={getValuesFromForm1} firstForm={firstForm} />
          }
          {showOther === true &&
            <Row>
              <Col lg={6} style={{ backgroundColor: "white" }}>
                <Row style={{ height: "100%" }}>
                  <Col lg={12} style={{ position: "absolute", top: "50%" }}>
                    <center>
                      <h1>Statutory Details</h1>
                    </center>
                  </Col>
                </Row>
              </Col>
              <Col lg={6}>
                <SecondForm getValuesFromForm2={getValuesFromForm2} secondForm={secondForm} firstForm={firstForm} />
              </Col>
              <Col lg={6}>

              </Col>
            </Row>
          }
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default AddCompany
