import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
  Input,
  Form
} from "reactstrap"
import classnames from "classnames"
import { isEmpty } from "lodash"

//Import Star Ratings
import StarRatings from "react-star-ratings"

//Import Product Images

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import actions

import { get, post } from "../../helpers/api_helper";
import { success, error, Loader } from "../../service/alerts";

//redux
import { useDispatch, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";
import AddCompany from "pages/company"
import Swal from 'sweetalert2'


const SellerProductDetail = props => {
  const dispatch = useDispatch()
  const [product, setProduct] = useState({});
  const [orders, setOrders] = useState({});
  const [sellerDetails, setsellerDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const [approvedProducts, setApprovedProducts] = useState([]);
  const [activedProducts, setActivedProducts] = useState([])
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [brands, setBrands] = useState("");
  const togglePopup = () => setModal(!modal);
  const [filter, setFilter] = useState([]);
  const [search, setSearch] = useState("");


  const {
    match: { params },
  } = props
  const [activeTab, setActiveTab] = useState(0);
  const getProductDetails = async () => {
    const result = await get(`/api/v1/seller/seller/details/?id=${params.id}`);
    if (result && result.length > 0) {
      setsellerDetails(result[0]);
      get(`/api/v1/products/vendorproducts/?user_id=${result[0].user_id}`).then(res => {
        setApprovedProducts(res);
      })
      get(`/api/v1/seller/sellerbrands/?user_id=${result[0].user_id}`).then(res => {
        setBrands(res.brands.join())
      })
    } else {
      setsellerDetails({});
      setApprovedProducts([]);
      setActivedProducts([]);
    }

  }
  const actionsClick = async (type) => {
    let result = null;
    if (type == "remove") {

      const { reason: text } = await
        Swal.fire({
          input: 'textarea',
          inputLabel: 'Message',
          inputPlaceholder: 'Type reason here...',
          inputAttributes: {
            'aria-label': 'Type reason here'
          },
          showCancelButton: true,
          confirmButtonColor: '#556ee6',
          cancelButtonColor: '#adb1b4',
          inputValidator: (reason) => {
            if (!reason) {
              return 'You need to write reason!'
            } else {
              setLoader(true);
              post(`/api/v1/seller/seller/status/`, { "user_id": sellerDetails.user_id, "isActive": false, reason })
                .then(res => {
                  setLoader(false);
                  getProductDetails();
                  success("Able to Deactive the seller")
                }).catch(err => {
                  setLoader(false);
                  error("Sorry not able to deactive");
                })
            }
          }
        })

    } else if (type == "approve") {
      post(`/api/v1/seller/seller/approve/`, { "user_id": sellerDetails.user_id, "isApproved": true })
        .then(res => {
          setLoader(false);
          getProductDetails();
          success("seller has been approved")
        }).catch(err => {
          setLoader(false);
          error("Sorry not able to Approve seller");
        })
    }
    else if (type == "map") {
      // result = await post(`/api/v1/product/inactiveproduct/`, { id: params.id })
      history.push(`/map-products/${params.id}/${sellerDetails.user_id}`)
    }
    else if (type == "active") {
      post(`/api/v1/seller/seller/status/`,
        { "user_id": sellerDetails.user_id, "isActive": true }).then(res => {
          setLoader(false);
          getProductDetails();
          success("Able to Active the seller")
        }).catch(err => {
          setLoader(false);
          error("Sorry not able to Active seller");
        })
    } else if (type == "reject") {
      post(`/api/v1/seller/seller/reject/`,
        { "user_id": sellerDetails.user_id, "isRejected": true }).then(res => {
          setLoader(false);
          getProductDetails();
          success("Able to Reject the seller")
        }).catch(err => {
          setLoader(false);
          error("Sorry not able to Reject seller");
        })
    } else if (type == "edit") {
      history.push(`/add-company/${sellerDetails.user_id}`)
    }
  }
  useEffect(() => {
    if (params) {
      getProductDetails(1)
    }
  }, [params])
  const cardsGenerate = (product, key) => {
    return (<Col xl="6" sm="6" key={"_col_" + key}>
      <Card
        onClick={() =>
          history.push(
            `/ecommerce-product-get-map/${product.prod_id}/${sellerDetails.user_id}`
          )
        }
      >
        <CardBody>
          <div className="product-img position-relative">

            <span className="text-muted me-2">
              Product Name
            </span>
            <h5 className="mb-3 text-truncate">{product.prod_name}</h5>
            <img
              src={product.image ?
                product.image[0].startsWith("https://") ?
                  product.image[0] : `https://${product.image[0]}` : ""}
              alt=""
              className="img-fluid mx-auto d-block"
            />
          </div>
          <div className="mt-4 text-center">

            <span className="text-muted me-2">
              Product Category
            </span>
            <h5 className="mb-3 text-truncate">{product.category}</h5>
            <span className="text-muted me-2">
              Seller Part Number
            </span>
            <h5 className="mb-3 text-truncate">{product.seller_part_no}</h5>
            <span className="text-muted me-2">
              Brand
            </span>
            <h5 className="mb-3 text-truncate">{product.brand}</h5>
            <span className="text-muted me-2">
              Type
            </span>
            <h5 className="mb-3 text-truncate">{product.type}</h5>
            <span className="text-muted me-2">
              Stock
            </span>
            <h5 className="mb-3 text-truncate">{product.stock}</h5>
            <span className="text-muted me-2">
              Selling price
            </span>
            <h5 className="mb-3 text-truncate">{product.selling_price}</h5>
          </div>
        </CardBody>
      </Card>
    </Col>
    )
  }
  const OpenBankDetails = (props) => {
    const {
      buttonLabel,
      className
    } = props;
    return (<div>
      <Modal isOpen={modal} toggle={togglePopup} className={className}>
        <ModalHeader toggle={togglePopup}>ACCOUNT DETAILS</ModalHeader>
        <ModalBody>
          <span className="text-muted me-2">
            Seller Account Details
          </span>
          <h6 className="mt-1 mb-3">{sellerDetails.seller_account_details}</h6>
          <span className="text-muted me-2">
            Seller Bank
          </span>
          <h6 className="mt-1 mb-3">{sellerDetails.seller_bank}</h6>
          <span className="text-muted me-2">
            Seller Bank IFSC
          </span>
          <h6 className="mt-1 mb-3">{sellerDetails.seller_bank_IFSC}</h6>
          <span className="text-muted me-2">
            Seller Bank Branch
          </span>
          <h6 className="mt-1 mb-3">{sellerDetails.seller_bank_branch}</h6>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={togglePopup}>OK</Button>
        </ModalFooter>
      </Modal>
    </div>)
  }
  const onSearch = async (searchValue, filterValue) => {
    let url = "/api/v1/search/?q=vendor_products&users_id=" + sellerDetails.user_id;
    if (searchValue) {
      url = `${url}&s=${searchValue}`
    }
    if (filterValue) {
      let filter = ""
      filterValue && filterValue.map(items => {
        if (items.value == "all") {
          filter = `${filter}&all=True`
        }
        if (items.value == "0") {
          filter = `${filter}&stock=0`
        }
        if (items.value == "in_active") {
          filter = `${filter}&inactive=False`
        }
        if (items.value == "active") {
          filter = `${filter}&active=True`
        }
        if (items.value == "low_stock") {
          filter = `${filter}&total_stock=5`
        }
        if (items.value == "Instockproducts") {
          filter = `${filter}&instock=1`
        }
        if (items.value == "isApproved") {
          filter = `${filter}&isApproved=False`
        }
      })
      url = `${url}${filter}`
    }
    get(url).then(res => {
      setApprovedProducts(res);
    }).catch(err => {
      setApprovedProducts([]);
    })
    setSearch(searchValue);
    setFilter(filterValue);
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Seller Detail |  BrakeUp
          </title>
        </MetaTags>
        <Container fluid>
          {loader && <Loader />}
          <OpenBankDetails />
          <Breadcrumbs title="Commercial" breadcrumbItem="Seller Detail" />
          <Row className="mb-3">
            <Col lg="7" sm="6">
              <div className="search-box me-2">
                <div className="position-relative">
                  <Input
                    type="text"
                    className="form-control border-0"
                    placeholder="Search Buyer by phone number, ID, Name, Product"
                    onChange={(e) => onSearch(e.target.value, filter)}
                  />
                  <i className="bx bx-search-alt search-icon" />
                </div>
              </div>
            </Col>
            <Col lg="5" sm="6">
              <div style={{ width: "300px" }}>
                <Select
                  style={{ width: "200px" }}
                  classNamePrefix="select"
                  name="filers"
                  placeholder="Filters"
                  isMulti
                  options={[{ label: "All Products", value: "all" },
                  { label: "Zero Stock Products", value: "0" },
                  { label: "Low Stock", value: "low_stock" },
                  { label: "in Active Products", value: "in_active" },
                  { label: "Active Products", value: "active" },
                  { label: "New Product Within Month", value: "new_product" },
                  { label: "Frequently Ordered", value: "frequently_ordered" },
                  { label: "In stock products", value: "Instockproducts" },
                  { label: "Pending Approval", value: "isApproved" },


                  ]}
                  onChange={e => {
                    onSearch(search, e)
                  }}
                />
              </div>
            </Col>
          </Row>
          {sellerDetails &&
            Object.keys(sellerDetails).length > 0 && (
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xl="4">
                          <div className="product-detai-imgs">
                            <Row>
                              <Col md="10" xs="10">
                                <div>
                                  <img
                                    src={sellerDetails.seller_logo.startsWith("https://") ? sellerDetails.seller_logo : `https://${sellerDetails.seller_logo}`}
                                    alt=""
                                    id={`expandedImg${1}`}
                                    className="img-fluid mx-auto d-block"
                                  />
                                </div>
                              </Col>

                            </Row>
                          </div>
                          <div className="mt-4 mt-xl-3">
                            <span className="text-muted me-2">
                              Seller Number
                            </span>
                            <h6 className="mt-1 mb-3">{sellerDetails.seller_id}</h6>
                            <span className="text-muted me-2">
                              Seller Name
                            </span>
                            <h6 className="mt-1 mb-3">{sellerDetails.seller_name}</h6>
                            <span className="text-muted me-2">
                              Phone number
                            </span>
                            <h6 className="mt-1 mb-3">{sellerDetails.seller_mobile}</h6>

                            <span className="text-muted me-2">
                              Address
                            </span>
                            <h6 className="mt-1 mb-3">{sellerDetails.seller_address}</h6>
                            <span className="text-muted me-2">
                              GST Number
                            </span>
                            <h6 className="mt-1 mb-3">{sellerDetails?.GST_number}</h6>
                            <span className="text-muted me-2">
                              PAN
                            </span>
                            <h6 className="mt-1 mb-3">{sellerDetails?.PAN}</h6>
                            <span className="text-muted me-2">
                              Authorized Person
                            </span>
                            <h6 className="mt-1 mb-3">{sellerDetails?.authorized_person}</h6>
                            <span className="text-muted me-2">
                              Company name
                            </span>
                            <h6 className="mt-1 mb-3">{sellerDetails?.company_name}</h6>
                            <span className="text-muted me-2">
                              Pickup location
                            </span>
                            <h6 className="mt-1 mb-3">{sellerDetails?.pickup_location}</h6>
                            <span className="text-muted me-2">
                              Email
                            </span>
                            <h6 className="mt-1 mb-3">{sellerDetails?.email}</h6>
                            <span className="text-muted me-2">
                              Pincode
                            </span>
                            <h6 className="mt-1 mb-3">{sellerDetails?.pincode}</h6>
                            <span className="text-muted me-2">
                              Brand Dealing with
                            </span>
                            <h6 className="mt-1 mb-3">{brands ? brands : "-"}</h6>
                            <div onClick={() => setModal(true)}>
                              <span className="text-muted me-2" style={{ cursor: "pointer" }}>
                                Account Details
                              </span>
                            </div><br></br>
                            {/* <h6 className="mt-1 mb-3">{sellerDetails.seller_account_details}</h6> */}
                            <span className="text-muted me-2">
                              Order details
                            </span>
                            <h6 className="mt-1 mb-3">{sellerDetails.orders ? sellerDetails.orders : "-"}</h6>
                          </div>
                          {sellerDetails.seller_Active == true && <Row>
                            <Col sm="6">
                              <Button
                                type="button"
                                color="primary"
                                className="btn  mt-2 me-1"
                                onClick={() => actionsClick("map")}
                                style={{ width: "116px" }}
                              >
                                Map Product
                              </Button>
                            </Col>
                          </Row>}
                          {sellerDetails.seller_Approved == false && <Row>
                            <Col sm="6">
                              <Button
                                type="button"
                                color="success"
                                className="btn  mt-2 me-1"
                                onClick={() => actionsClick("approve")}
                                style={{ width: "116px" }}
                              >
                                Approve Seller
                              </Button>
                            </Col>
                          </Row>}
                          {sellerDetails.seller_Active == true && <Row>
                            <Col sm="6">
                              <Button
                                type="button"
                                color="danger"
                                className="btn  mt-2 me-1"
                                onClick={() => actionsClick("remove")}
                                style={{ width: "116px" }}
                              >
                                De Active Seller
                              </Button>
                            </Col>
                          </Row>}
                          {sellerDetails.seller_Active == false && <Row>
                            <Col sm="6">
                              <Button
                                type="button"
                                color="success"
                                className="btn  mt-2 me-1"
                                onClick={() => actionsClick("active")}
                                style={{ width: "116px" }}
                              >
                                Active Seller
                              </Button>
                            </Col>
                          </Row>}
                          {sellerDetails.seller_Approved == false && <Row>
                            <Col sm="6">
                              <Button
                                type="button"
                                color="danger"
                                className="btn  mt-2 me-1"
                                onClick={() => actionsClick("reject")}
                                style={{ width: "116px" }}
                              >
                                Reject Seller
                              </Button>
                            </Col>

                          </Row>}
                          <Row>
                            <Col sm="6">
                              <Button
                                type="button"
                                color="dark"
                                className="btn  mt-2 me-1"
                                onClick={() => actionsClick("edit")}
                                style={{ width: "116px" }}
                              >
                                Edit Seller
                              </Button>
                            </Col>
                          </Row>
                        </Col>

                        <Col xl="8">

                          <Row>
                            {!isEmpty(approvedProducts) &&
                              approvedProducts.map((product, key) => (
                                cardsGenerate(product, key)
                              ))}
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
        </Container>
      </div>
    </React.Fragment>
  )
}

SellerProductDetail.propTypes = {
  product: PropTypes.object,
  onGetProductDetail: PropTypes.func,
}

export default SellerProductDetail
