import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  DropdownItem,
  Row,
  Col,
  Table,
  Button,
  Label,
  Input

} from "reactstrap"
import companies from "assets/images/companies"

import { Link } from 'react-router-dom';
import moment from 'moment';
import Select from "react-select"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"
import SellerRow from './productRow'


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from 'react-router-dom';
import SellerTable from './productRow';
import { get, post } from "../../helpers/api_helper";



const MapProducts = props => {
  const [modal, setmodal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('');
  const [pendingApprovals, setPendingApprovals] = useState([]);
  const history = useHistory()
  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }))

  const onSearchChange = async (search) => {
    let url = "/api/v1/search/?q=product"
    url = `${url}&s=${search}`
    get(url).then(res => {
      setPendingApprovals(res?.data);
    }).catch(err => {
      setPendingApprovals([]);
    })
  }

  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true)
    }, 2000)
  }, [])

  const [periodData, setPeriodData] = useState([])

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])


  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch]);

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }



  const handleDeleteProject = project => {
    dispatch(onDeleteProject(project))
  }
  const onClickRow = (items) => {
    history.push(`/seller-details/${items["_id"]["$oid"]}`)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Products | BrakeUp</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Products")}
            breadcrumbItem={props.t("Products")}
          />
          <br />
          <Row>
            <div style={{ display:"flex", justifyContent:"space-between" }}>
            <div className="search-box me-2">
              <div className="position-relative">
                <Input
                  type="text"
                  className="form-control border-0"
                  placeholder="Search Products..."
                  onChange={(e) => {onSearchChange(e.target.value)}}
                />
                <i className="bx bx-search-alt search-icon" />
              </div>
            </div>
              <Button color="primary" onClick={() => history.push("/ecommerce-add-product")}>Add New Product</Button>
            </div>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  <Table className="project-list-table table-nowrap align-middle  table">
                    <tbody>

                      <SellerRow
                        onClick={onClickRow}
                        pendingApprovals={pendingApprovals}
                        setPendingApprovals={setPendingApprovals}
                        color={'grey'}
                      />
                      <br />
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>


    </React.Fragment>
  )
}

MapProducts.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(MapProducts)
