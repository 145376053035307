import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import MetaTags from 'react-meta-tags';

import {
    Button,
    Card,
    CardBody,
    CardSubtitle,
    CardTitle,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    TabContent,
    Table,
    TabPane,
} from "reactstrap"
import Select from "react-select"
import Dropzone from "react-dropzone"
import { API_URL } from "../../helpers/api_helper";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { CustomInput, CustomSelect } from "../Ecommerce/customInputs";
import { post, get } from "../../helpers/api_helper";
import axios from 'axios';
import StarRatings from "react-star-ratings"
import { Loader } from "../../helpers/loader";
import { useParams } from "react-router-dom";
import { ErrorToast } from "../../helpers/toast";
import { useHistory } from "react-router-dom";
import {success, error} from "../../service/alerts";

const AddMake = () => {
    const { handleSubmit, errors, control, setValue, watch, register, unregister } = useForm({
        mode: 'onChange'
    });
    const history = useHistory();
    const [selectedFiles, setselectedFiles] = useState([])
    const [fuelType, setFuelType] = useState("petrol");
    const [loader, setLoader] = useState(false);
    const [imageLoader, setImageLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    const [message, setMessage] = useState("")
    const { id } = useParams();
    const getProductDetails = async (id) => {
        const result = await get(`/api/v1/vehiclemake/?id=${id}`);
        console.log(result,'HEREEEEEEEEEEEEEEE');
        if(result && result.data){
            setselectedFiles(result.data[0].image); 
            setValue("make",result.data[0].make)
            
        }
    }
    useEffect(() => {
        if (id) { getProductDetails(id); }

    }, [id])
    const handleAcceptedFiles = (acceptedFiles) => {
        let formData = new FormData();
        formData.append("name", acceptedFiles[0].name);
        formData.append("type", "product");
        formData.append("file", acceptedFiles[0]);
        setImageLoader(true);
        fetch( API_URL + `/api/v1/products/uploadimage/`,      
        {
                method: 'POST',
                body: formData
            }
        )
            .then(response => response.json())
            .then(res => {
                let selected = [];
                selected.push(`${res.url}`);
                setselectedFiles(selected);
                setImageLoader(false);
            })
    }
    const onSubmit = async (items) => {
        if(selectedFiles.length ==0){
            error("Please add image");
            return
        }
        
        let values = {
                make:items.make,
                image:selectedFiles[0],
            }
        setLoader(true);
        try {
            let urls = "/api/v1/vehiclemake/";
            if (id) {
                urls = "/api/v1/update_make/";
                values.id = id;
            }
            post(urls, values).then(res => {
                success("able to create make");
                setLoader(false);
                history.push("/AddVehicles")
            }).catch(err => {
                setLoader(false);
                error(err?.response?.data?.message)
            });


        } catch (err) {
            setLoader(false);
        }
    }
    const cancelMake =() =>{
        setValue("make", "");
        setselectedFiles([])
    }
    return (
        <React.Fragment>
            <div className="page-content">
                {showError && <ErrorToast
                    message={message}
                    showError={showError}
                    setShowError={setShowError} />}
                <MetaTags>
                    <title>Add Make |  BrakeUp</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Commercial" breadcrumbItem="Add Make" />

                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Row>
                                            <Col sm="5">
                                                <div className="mb-3">
                                                    <Label htmlFor="productname">Make Name</Label>
                                                    <Controller
                                                        as={<CustomInput />}
                                                        defaultValue={null}
                                                        name="make"
                                                        onChange={([e]) => {
                                                            return { value: e };
                                                        }}
                                                        control={control}
                                                        rules={{ required: true }}
                                                    />
                                                    {errors.make && <span style={{ color: "red" }}>Make name is required.</span>}
                                                </div>
                                                <div className="mb-3">
                                                    <Dropzone
                                                        onDrop={acceptedFiles => {
                                                            handleAcceptedFiles(acceptedFiles)
                                                        }}
                                                    >
                                                        {({ getRootProps, getInputProps }) => (
                                                            <div
                                                                {...getRootProps()}
                                                                style={{
                                                                    height: "160px",
                                                                    border: "1px dashed #60e0ff",
                                                                    textAlign: "center",
                                                                    cursor: "pointer"
                                                                }}
                                                            >
                                                                <input {...getInputProps()} />
                                                                {imageLoader && <Loader />}
                                                                {selectedFiles.length == 0 && <h4 style={{ marginTop: "64px" }}>Add Image</h4>}
                                                                {selectedFiles.map((f, i) => {
                                                                return (
                                                                    <img
                                                                    data-dz-thumbnail=""
                                                                    height="80"
                                                                    className="avatar-sm rounded bg-light"
                                                                    style={{ width: "100%", height: "100%" }}
                                                                    alt={"image"}
                                                                    src={f}
                                                                    />)
                                                                })
                                                                }
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="d-flex flex-wrap gap-2">
                                            {loader && <Loader />}
                                            <Button
                                                color="secondary"
                                                className="btn"
                                                onClick={()=> cancelMake()}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="btn "
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default AddMake
