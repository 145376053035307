import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  Button
} from "reactstrap"
import classnames from "classnames"
import { isEmpty, map } from "lodash"

//Import Star Ratings
import StarRatings from "react-star-ratings"

// RangeSlider
import Nouislider from "nouislider-react"
import "nouislider/distribute/nouislider.css"

//Import Product Images
import { productImages } from "assets/images/product"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import data
import { discountData, productsData } from "common/data"

//Import actions
import { getProducts as onGetProducts } from "store/e-commerce/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { get } from "helpers/api_helper";
import Select from "react-select";
import { post, } from "helpers/api_helper";
import { success, error, Loader} from "../../service/alerts";

const ReplacementSummary = props => {
  const dispatch = useDispatch()
  const { history } = props

  const [old_order, setOldOrder] = useState('')
  const [new_order, setNewOrder] = useState('')



 
  useEffect(()=>{
    let old_order = localStorage.getItem('replace_item')
    let new_order = localStorage.getItem('new_item')
    if(old_order && new_order){
        // console.log(old_order, "oldddddddd")
        // console.log(new_order, "newwwwwwwwww")
        setOldOrder(JSON.parse(old_order))
        setNewOrder(JSON.parse(new_order))
    }else{
        history.push('/orders')
    }
}, [])

const onSubmit = () => {
    let comment = localStorage.getItem('replacement_comment')
    // console.log(new_order, "kkkkkk")
    post("/api/v1/products/replace_product/",{
        "vend_id":old_order.vendor_id,
        "current_pro_id":old_order.prod_id,
        "new_pro_id":new_order._id.$oid,
        "current_order_id":old_order.order_id,
        "new_pro_price":new_order.price,
        "current_pro_price": old_order.price,
        "current_quantity":1,
        "new_quantity":1,
        "note":comment ? comment : ""
    }
    ).then(res=>{
        success("Replacement Order Placed");
    }).catch(err=>{
        error("Error Placing replacement Order");
    })
}


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Replacement Summary |  BrakeUp</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Commercial" breadcrumbItem="Replacement Summary " />
          <Row>
            <Col lg="12">
              <Row className="mb-3">
                <Col xl="4" sm="6">
           
                </Col>
                <Col lg="8" sm="6">
     
                </Col>
              </Row>
              <Row className="d-flex justify-content-around" >
              <Col xl="4" sm="6" key={"_col_"}>
                  <h3>Current Order</h3>
                    {
                        old_order && 
                        <Card
                        onClick={() =>{
                            
                        }
                        }
                      >
                        <CardBody>
                          <div className="product-img position-relative">
                

                            <img
                              src={old_order.image[0].startsWith("https://")? old_order.image[0]: `https://${old_order.image[0]}`}
                              alt=""
                              className="img-fluid mx-auto d-block"
                            />
                          </div>
                          <div className="mt-4 text-center">
                              <span className="text-muted me-2">
                                Product Name
                              </span>
                              <h5 className="mb-3 text-truncate">
                                <Link
                                  to={"/ecommerce-product-detail/" + old_order._id.$oid}
                                  className="text-dark"
                                >
                                  {old_order.prod_name}
                                </Link>
                              </h5>
                              <span className="text-muted me-2">
                                Product Category
                              </span>
                            <h5 className="mb-3 text-truncate">{old_order.category}</h5>
                            <span className="text-muted me-2">
                                Product ID
                              </span>
                            <h5 className="mb-3 text-truncate">{old_order.product_id}</h5>
                            <span className="text-muted me-2">
                                Brand
                            </span>
                            <h5 className="mb-3 text-truncate">{old_order.brand}</h5>
                            <span className="text-muted me-2">
                                Type
                            </span>
                            <h5 className="mb-3 text-truncate">{old_order.type}</h5>
                            <span className="text-muted me-2">
                                Stock
                            </span>
                            <h5 className="mb-3 text-truncate">{old_order.total_stock}</h5>
                            <span className="text-muted me-2">
                                No of Sellers
                            </span>
                            <h5 className="mb-3 text-truncate">{old_order.no_of_sellers}</h5>

                          </div>
                        </CardBody>
                      </Card>
                    }



                    </Col>
                


                    <Col xl="4" sm="6" key={"_col_"}>
                    <h3>New Order</h3>
                    {
                      new_order &&
                      <Card
                        onClick={() =>{}
                        }
                      >
                        <CardBody>
                          <div className="product-img position-relative">
                            {/* {product.isOffer ? (
                              <div className="avatar-sm product-ribbon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  {`-${product.offer}%`}
                                </span>
                              </div>
                            ) : null} */}

                            <img
                              src={new_order.image[0].startsWith("https://")? new_order.image[0]: `https://${new_order.image[0]}`}
                              alt=""
                              className="img-fluid mx-auto d-block"
                            />
                          </div>
                          <div className="mt-4 text-center">
                              <span className="text-muted me-2">
                                Product Name
                              </span>
                              <h5 className="mb-3 text-truncate">
                                <Link
                                  to={"/ecommerce-product-detail/" + new_order._id.$oid}
                                  className="text-dark"
                                >
                                  {new_order.prod_name}
                                </Link>
                              </h5>
                              <span className="text-muted me-2">
                                Product Category
                              </span>
                            <h5 className="mb-3 text-truncate">{new_order.category}</h5>
                            <span className="text-muted me-2">
                                Product ID
                              </span>
                            <h5 className="mb-3 text-truncate">{new_order.product_id}</h5>
                            <span className="text-muted me-2">
                                Brand
                            </span>
                            <h5 className="mb-3 text-truncate">{new_order.brand}</h5>
                            <span className="text-muted me-2">
                                Type
                            </span>
                            <h5 className="mb-3 text-truncate">{new_order.type}</h5>
                            <span className="text-muted me-2">
                                Stock
                            </span>
                            <h5 className="mb-3 text-truncate">{new_order.total_stock}</h5>
                            <span className="text-muted me-2">
                                No of Sellers
                            </span>
                            <h5 className="mb-3 text-truncate">{new_order.no_of_sellers}</h5>

                          </div>
                        </CardBody>
                      </Card>
                  }
                    </Col>
              </Row>

              <div className="m-3 float-end" >

                <Button
                    type="submit"
                    color="primary"
                    className="btn "
                    onClick={()=> onSubmit()}
                >
                    Proceed
                </Button>
                </div>
            </Col>
          </Row>

        </Container>

      </div>

    </React.Fragment>
  )
}

ReplacementSummary.propTypes = {
  products: PropTypes.array,
  history: PropTypes.object,
  onGetProducts: PropTypes.func,
}

export default withRouter(ReplacementSummary)
