import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import moment from 'moment';
//i18n
import { withTranslation } from "react-i18next";
//redux
import { useSelector } from "react-redux";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';


const OrderRow = props => {
  const [modal, setmodal] = useState(false)
  const [pendingApprovals, setPendingApprovals] = useState([]);
  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }))
  useEffect(() => {
  }, [])


  return (
    <React.Fragment>
      <tr className={`tr-border-${props.color} mb-2`}>
        <td >
          <div className="text-muted mb-1 p-2 bordered-div" >
            Code
          </div>
        </td>
        <td>
          <div className="text-muted mb-1 p-2 bordered-div" >
            Type
          </div>
        </td>
        <td>
          <div className="text-muted mb-1 p-2 bordered-div" >
            Delivery Charge
          </div>
        </td>

        <td>
          <div className="text-muted mb-1 p-2 bordered-div" >
            Valid From
          </div>
        </td>
        <td>
          <div className="text-muted mb-1 p-2 bordered-div" >
            Valid To
          </div>

        </td>
        <td>
          <div className="text-muted mb-1 p-2 bordered-div" >
            Min Cart Value
          </div>

        </td>
        <td>
          <div className="text-muted mb-1 p-2 bordered-div" >
            Max Cart value
          </div>

        </td>
        <td>
          <div className="text-muted mb-1 p-2 bordered-div" >
            Value
          </div>
        </td>
        <td>
          <div className="text-muted mb-1 p-2 bordered-div" >
            Max Discount value
          </div>
        </td>
        <td>
          <div className="text-muted mb-1 p-2 bordered-div" >
            No of Use
          </div>
        </td>
        {props.isActive?<td>
          <div className="text-muted mb-1 p-2 bordered-div" >
            Action
          </div>
        </td>:<td>
          <div className="text-muted mb-1 p-2 bordered-div" >
            Status
          </div>
        </td>}
      </tr>
      {props.pending && props.pending.map(items => {
        return (
          <tr className={`tr-border-${props.color} mb-2`}>
            <td >
              <div className="text-muted mb-1 p-2 bordered-div" >
                {items.promo_code}
              </div>
            </td>
            <td>
              <div className="text-muted mb-1 p-2 bordered-div" >
                {items.type}
              </div>
            </td>
            <td>
              <div className="text-muted mb-1 p-1 bordered-div" >
                {items.shipping_charge ? items.shipping_charge.toString() :items.shipping_charge == false ? "false" : "" }
              </div>
            </td>

            <td>
              <div className="text-muted mb-1 p-2 bordered-div" >
              {moment(items.validity_frm["$date"]).format("DD MMM Y")}
              </div>


            </td>
            <td>
              <div className="text-muted mb-1 p-2 bordered-div" >
                {moment(items.validity_to["$date"]).format("DD MMM Y")}
              </div>

            </td>
            <td>
              <div className="text-muted mb-1 p-2 bordered-div" >
                {items.min_cart_value}
              </div>

            </td>
            <td>
              <div className="text-muted mb-1 p-2 bordered-div" >
              {items.max_cart_value}
              </div>

            </td>
            <td>
              <div className="text-muted mb-1 p-2 bordered-div" >
              {items.value}
              </div>
            </td>
            <td>
              <div className="text-muted mb-1 p-2 bordered-div" >
              {items.max_discount}
              </div>
            </td>
            <td>
              <div className="text-muted mb-1 p-2 bordered-div" >
              {items.no_of_use}
              </div>
            </td>
            {props.isActive?<td>
                <DeleteOutlined style={{ float: "right", color: "red", marginRight: "15px" }} 
                onClick={() => props.deleteCode(items._id.$oid)} />
            </td>:
            <td>
                <span>Inactive</span>
            </td>}

          </tr>
        )
      })}

    </React.Fragment>
  )
}

OrderRow.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(OrderRow)
