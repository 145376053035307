import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle, Label, Table } from "reactstrap"
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import { get } from "../../helpers/api_helper";

const SellerTable = (props) => {

  const [pendingApprovals, setPendingApprovals] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("")
  const getPendingApprovals = async (url) => {
    const result = await get(url);
    if (result.data) {
      setPendingApprovals(result.data);
    }
  }

  useEffect(() => {
    // getPendingApprovals(`/api/v1/seller/seller_tab/detail/`);
  }, [])

 
  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <React.Fragment>
                <Table className="project-list-table table-nowrap align-middle  table">
                  <tbody>
                    <tr className={`tr-border-${props.color} mb-2`} >
                      <td >
                        <div className="text-muted mb-1 p-2 bordered-div" >
                          Seller Number
                        </div>
                      </td>
                      <td>
                        <div className="text-muted mb-1 p-2 bordered-div" >
                          Seller Logo
                        </div>
                      </td>

                      <td>
                        <div className="text-muted mb-1 p-2 bordered-div" >
                          Name of seller
                        </div>
                      </td>
                      <td>
                        <div className="text-muted mb-1 p-2 bordered-div" >
                          Phone Number
                        </div>

                      </td>
                      <td>
                        <div className="text-muted mb-1 p-2 bordered-div" >
                          City
                        </div>

                      </td>
                      <td>
                        <div className="text-muted mb-1 p-2 bordered-div" >
                          Open Order
                        </div>

                      </td>
                      <td>
                        <div className="text-muted mb-1 p-2 bordered-div" >
                          Completed Order
                        </div>
                      </td>
                    </tr>
                    {props.allData && props.allData.map(items => {
                      return (
                        <tr className={`tr-border-${props.color} mb-2`} onClick={()=>props.onClick(items)}>
                          <td >
                            <div className="text-muted mb-1 p-2 bordered-div" >
                              {items.seller_id}
                            </div>
                          </td>
                          <td>
                            <img
                              src={items.company_logo}
                              alt=""
                              className="avatar-sm"
                            />
                          </td>

                          <td>
                            <div className="text-muted mb-1 p-2 bordered-div" >
                              {items.display_name}
                            </div>


                          </td>
                          <td>
                            <div className="text-muted mb-1 p-2 bordered-div" >
                              {items.mobile}
                            </div>

                          </td>
                          <td>
                            <div className="text-muted mb-1 p-2 bordered-div" >
                              {items.city}
                            </div>

                          </td>
                          <td>
                            <div className="text-muted mb-1 p-2 bordered-div" >
                              {items.open_order}
                            </div>

                          </td>
                          <td>
                            <div className="text-muted mb-1 p-2 bordered-div" >
                              {items.completed_order}
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </React.Fragment>


            </CardBody>
          </Card>
        </Col>
      </Row>

    </React.Fragment>
  )
}

export default SellerTable
