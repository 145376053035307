import axios from "axios"
// import accessToken from "./jwt-token-access/accessToken"


const accessToken = JSON.parse(localStorage.getItem("adminToken") || "{}");

//pass new generated access token here
const token = "Bearer " + accessToken?.access_token;

//apply base url for axios
export const API_URL = "https://api.brakeup.in";

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = token
// axiosApi.defaults.headers.common["Access-Control-Allow-Origin"] = "*"


axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  // console.log(data, 'datadatadatadatadatadatadatadatadata')
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
