import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes, { string } from "prop-types"
import { Link, withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  Button,
  Table,
} from "reactstrap"
import classnames from "classnames"
import { isEmpty, map } from "lodash"
import Select from "react-select"

//Import Star Ratings

// RangeSlider
import "nouislider/distribute/nouislider.css"

//Import Product Images

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { useSelector, useDispatch } from "react-redux"
import { get, post } from "../../helpers/api_helper"
import { success, error, Loader } from "../../service/alerts"
import SellerRow from "./sellerRow"

const Brand = props => {
  const dispatch = useDispatch()
  const { history } = props
  const [productList, setProductList] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [page, setPage] = useState(1)
  const [typeFilter, setTypeFilter] = useState("")
  // eslint-disable-next-line no-unused-vars
  const [totalPage, setTotalPage] = useState(5)

  const [products, setProducts] = useState([])
  const getProducts = async () => {
    const result = await get("/api/v1/products/add_brand/")
    if (result.data) {
      setProductList(result.data)
    } else {
      setProductList([])
    }
  }
  useEffect(() => {
    getProducts()
  }, [])

  const onClickRow = items => {
    history.push(`/brand-details/${items["_id"]["$oid"]}`)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Brand | BrakeUp</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="BRANDS" breadcrumbItem="BRANDS" />
          <Row>
            <Col lg="12">
              <Row className="mb-3">
                <Col lg="7" sm="6"></Col>
                <Col lg="5" sm="6">
                  <div className="mt-2" style={{ float: "right" }}>
                    <Button
                      color="primary"
                      onClick={() => history.push("/add-brand/")}
                    >
                      Add NEW
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                {productList && productList.length > 0 && (
                  <Row>
                    <Col lg="6">
                      <div className="">
                        <div className="table-responsive">
                          <Table className="project-list-table table-nowrap align-middle  table">
                            <tbody>
                              <SellerRow
                                onClick={onClickRow}
                                color={"grey"}
                                pending={productList}
                              />
                              <br />
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Brand.propTypes = {
  products: PropTypes.array,
  history: PropTypes.object,
  onGetProducts: PropTypes.func,
}

export default withRouter(Brand)
