import React, { useState } from 'react';
import { Alert } from 'reactstrap';

export const ErrorToast = (props) => {
  const onDismiss = () => props.setShowError(false);

  return (
    <Alert color="danger" isOpen={props.showError} toggle={onDismiss} style={{textAlign:"center", 
                                                                        margin:"auto",
                                                                        width:"250px"}}>
        {props.message}
    </Alert>
  );
}