import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  DropdownItem,
  Row,
  Col,
  Table,
  Button,
  Label

} from "reactstrap"
import companies from "assets/images/companies"

import {Link} from 'react-router-dom';
import moment from 'moment';
import Select from "react-select"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import {useHistory }  from 'react-router-dom';
import DispatchTable from './dispatchTable'





const optionGroup = [
    { label: "All", value: "All" },
      { label: "Active", value: "Active" },
      { label: "Inactive", value: "Inactive" }
]
const Dispatch = props => {
  const [modal, setmodal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)
  const [selectedFilter,setSelectedFilter] = useState('');
    const history = useHistory()
  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }))



  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true)
    }, 2000)
  }, [])

  const [periodData, setPeriodData] = useState([])

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch]);

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }

 

  const handleDeleteProject = project => {
    dispatch(onDeleteProject(project))
  }
  const onClickRow = () => {
      history.push('/orders/12')
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dispatch | BrakeUp</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dispatch")}
            breadcrumbItem={props.t("Dispatch")}
          />

        {/* <Row>
            <div className="d-flex justify-content-between">
                <Button>
                    Pending Apprival
                </Button>
                <Button>
                    Add Seller
                </Button>
            </div>
        </Row> */}
        <br/>



          {/* <Row>
            <Col md={4}>
            <div className="mb-3">
                <Label>Filters</Label>
                <Select
                value={selectedFilter}
                onChange={(e) => {
                    setSelectedFilter(e)
                }}
                options={optionGroup}
                classNamePrefix="select2-selection"
                />
            </div>
            </Col>
          </Row> */}


          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  <DispatchTable/>
                
                </div>
              </div>
            </Col>
          </Row>




        </Container>
      </div>

 
    </React.Fragment>
  )
}

Dispatch.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dispatch)
