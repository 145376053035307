import React, {useState} from "react"
import { Row, Col, Card, CardBody, CardTitle, Label } from "reactstrap"
import MetaTags from 'react-meta-tags'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

const optionGroup = [
    { label: "All", value: "All" },
      { label: "Active", value: "Active" },
      { label: "Inactive", value: "Inactive" }
]

const Actions = () => (
  <div>
      <div className="form-check form-check-primary mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="customCheckcolor1"
            checked={true}
            onChange={() => {
            }}
          />

          <label
            className="form-check-label"
            htmlFor="customCheckcolor1"
          >
            Payment Initiated
          </label>
        </div>
        <div className="form-check form-check-primary mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="customCheckcolor1"
            checked={true}
            onChange={() => {
            }}
          />

          <label
            className="form-check-label"
            htmlFor="customCheckcolor1"
          >
            Payment Processed
          </label>
        </div>
    </div>
)

const SellerTable = () => {
    const [selectedFilter,setSelectedFilter] = useState('');


  const columns = [{
    dataField: 'order_id',
    text: 'Order Id',
    sort: true,
  }, {
    dataField: 'seller_id',
    text: 'Seller Id',
    sort: true
  }, {
    dataField: 'seller_address',
    text: 'Seller Name & Address',
    sort: true
  }, {
    dataField: 'bank_details',
    text: 'Bank Details',
    sort: true
  }, {
    dataField: 'amount',
    text: 'Amount to be Dispatched / Refunded',
    sort: true
  }, {
    dataField: 'actions',
    text: 'Actions',
    sort: true
  }];

  // Table Data
  const productData = [
    { "seller_id": 12,"order_id": 1, "seller_address": "Airi Satou", "bank_details": "Accountant", "city": "Tokyo", "open_order": "33", "actions":<Actions/>,  "amount": "162,700" },

    { "seller_id": 12,"order_id": 2, "seller_address": "Angelica Ramos", "bank_details": "Chief Executive Officer (CEO)", "city": "London", "open_order": "47", "actions": <Actions/>, "amount": "1,200,000" },

    { "seller_id": 12,"order_id": 3, "seller_address": "Ashton Cox", "bank_details": "Junior Technical Author", "city": "San Francisco", "open_order": "66", "actions": <Actions/>, "amount": "86,000" },

    { "seller_id": 12,"order_id": 4, "seller_address": "Bradley Greer", "bank_details": "Software Engineer", "city": "London", "open_order": "41", "actions": <Actions/>, "amount": "132,000" },

    { "seller_id": 12,"order_id": 5, "seller_address": "Brenden Wagner", "bank_details": "Software Engineer", "city": "San Francisco", "open_order": "28", "actions": <Actions/>, "amount": "206,850" },

    { "seller_id": 12,"order_id": 6, "seller_address": "Brielle Williamson", "bank_details": "Integration Specialist", "city": "New York", "open_order": "61", "actions": <Actions/>, "amount": "372,000" },

    { "seller_id": 12,"order_id": 7, "seller_address": "Bruno Nash", "bank_details": "Software Engineer", "city": "London", "open_order": "38", "actions": <Actions/>, "amount": "163,500" },

    { "seller_id": 12,"order_id": 8, "seller_address": "Caesar Vance", "bank_details": "Pre-Sales Support", "city": "New York", "open_order": "21", "actions": <Actions/>, "amount": "106,450" },

    { "seller_id": 12,"order_id": 9, "seller_address": "Cara Stevens", "bank_details": "Sales Assistant", "city": "New York", "open_order": "46", "actions": <Actions/>, "amount": "145,600" },

    { "seller_id": 12,"order_id": 10, "seller_address": "Cedric Kelly", "bank_details": "Senior Javascript Developer", "city": "Edinburgh", "open_order": "22", "actions": <Actions/>, "amount": "433,060" },

    { "seller_id": 12,"order_id": 11, "seller_address": "Marshall", "bank_details": "Regional Director", "city": "San Francisco", "open_order": "36", "actions": <Actions/>, "amount": "470,600" },

    { "seller_id": 12,"order_id": 12, "seller_address": "Hurst", "bank_details": "Javascript Developer", "city": "San Francisco", "open_order": "39", "actions": <Actions/>, "amount": "205,500" },

    { "seller_id": 12,"order_id": 13, "seller_address": "Rios", "bank_details": "Personnel Lead", "city": "Edinburgh", "open_order": "35", "actions": "2016", "amount": "217,500" },

    { "seller_id": 12,"order_id": 14, "seller_address": "Snider", "bank_details": "Customer Support", "city": "New York", "open_order": "27", "actions": "201", "amount": "112,000" },

    { "seller_id": 12,"order_id": 15, "seller_address": "Wilder", "bank_details": "Sales Assistant", "city": "Sidney", "open_order": "23", "actions": "20", "amount": "85,600" },

    { "seller_id": 12,"order_id": 16, "seller_address": "Camacho", "bank_details": "Support Engineer", "city": "San Francisco", "open_order": "47", "actions": "207", "amount": "87,500" },

    { "seller_id": 12,"order_id": 17, "seller_address": "Green", "bank_details": "Chief Operating Officer (COO)", "city": "San Francisco", "open_order": "48", "actions": "2011", "amount": "850,000" },

    { "seller_id": 12,"order_id": 18, "seller_address": "Winters", "bank_details": "Accountant", "city": "Tokyo", "open_order": "63", "actions": "20115", "amount": "170,750" },

    { "seller_id": 12,"order_id": 19, "seller_address": "Cortez", "bank_details": "Team Leader", "city": "San Francisco", "open_order": "22", "actions": "2026", "amount": "235,500" },

    { "seller_id": 12,"order_id": 20, "seller_address": "Joyce", "bank_details": "Developer", "city": "Edinburgh", "open_order": "42", "actions": "20122", "amount": "92,575" },

    { "seller_id": 12,"order_id": 21, "seller_address": "Gloria Little", "bank_details": "Systems Administrator", "city": "New York", "open_order": "59", "actions": "2000", "amount": "237,500" },

    { "seller_id": 12,"order_id": 22, "seller_address": "Haley Kennedy", "bank_details": "Senior Marketing Desi,ner", "city": "London", "open_order": "43", "actions": "2012/12/18", "amount": "313,500" },

    { "seller_id": 12,"order_id": 23, "seller_address": "Hermione Butler", "bank_details": "Regional Director", "city": "London", "open_order": "47", "actions": "2011/03/21", "amount": "356,250" },

    { "seller_id": 12,"order_id": 24, "seller_address": "Herrod Chandler", "bank_details": "Sales Assistant", "city": "San Francisco", "open_order": "59", "actions": "2012/08/06", "amount": "137,500" },

    { "seller_id": 12,"order_id": 25, "seller_address": "Hope Fuentes", "bank_details": "Secretary", "city": "San Francisco", "open_order": "41", "actions": "2010/02/12", "amount": "109,850" },

    { "seller_id": 12,"order_id": 26, "seller_address": "Howard Hatfield", "bank_details": "Office Manager", "city": "San Francisco", "open_order": "51", "actions": "2008/12/16", "amount": "164,500" },

    { "seller_id": 12,"order_id": 27, "seller_address": "Jackson Bradshaw", "bank_details": "Director", "city": "New York", "open_order": "65", "actions": "2008/09/26", "amount": "645,750" },

    { "seller_id": 12,"order_id": 28, "seller_address": "Jena Gaines", "bank_details": "Office Manager", "city": "London", "open_order": "30", "actions": "2008/12/19", "amount": "90,560" },

    { "seller_id": 12,"order_id": 29, "seller_address": "Jenette Caldwell", "bank_details": "Development Lead", "city": "New York", "open_order": "30", "actions": "2011/09/03", "amount": "345,000" },

    { "seller_id": 12,"order_id": 30, "seller_address": "Jennifer Acosta", "bank_details": "Junior Javascript Devel,per", "city": "Edinburgh", "open_order": "43", "actions": "2013/02/01", "amount": "75,650" }

  ];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (productData).length }];


  // Select All Button operation
  const selectRow = {
    mode: 'checkbox'
  }

  const { SearchBar } = Search;

  return (
    <React.Fragment>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='id'
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        columns={columns}
                        data={productData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>

                            <Row className="mb-2">
                              <Col md="4">
                              <Label>Filters</Label>
                                <div className="search-box me-2 mb-2 col-md-12">
                                    {/* <SearchBar
                                      {...toolkitProps.searchProps}
                                    /> */}
                                    {/* <Select
                                    value={selectedFilter}
                                    onChange={(e) => {
                                        setSelectedFilter(e)
                                    }}
                                    options={optionGroup}
                                    classNamePrefix="select2-selection"
                                    /> */}
                                    {/* <i className="bx bx-search-alt search-icon" /> */}
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"order_id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />

                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }</PaginationProvider>

                </CardBody>
              </Card>
            </Col>
          </Row>

    </React.Fragment>
  )
}

export default SellerTable
