import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Container, Label } from "reactstrap"
import MetaTags from "react-meta-tags"

import { get } from "helpers/api_helper"
import moment from "moment"

import "./check.css"

import { PaginationControl } from "react-bootstrap-pagination-control"

const checkOutReport = props => {
  const [checkoutData, setCheckoutData] = useState({})
  const pageToken = JSON.parse(sessionStorage.getItem("pageTokenReports" || {}))
  const [page, setPage] = useState(pageToken ? pageToken?.page : 1)

  useEffect(() => {
    getList(page)
  }, [])

  const getList = page => {
    get(`/api/v1/products/reports_address/?page_size=20&page_number=${page}`)
      .then(res => {
        setCheckoutData(res)
        // console.log(res.data, "getListgetListgetList")
      })
      .catch(error => {})
    setCheckoutData([])
  }

  const setPageToken = page => {
    let data = {
      page: page,
    }
    sessionStorage.setItem("pageTokenReports", JSON.stringify(data))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Reports | BrakeUp</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  <Row>
                    <Col className="col-12">
                      <Card className="mb-3">
                        <CardBody>
                          <Row className="mb-1">
                            <Col md="6">
                              <h2>Reports - Clicks on Checkout</h2>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                      {checkoutData?.totalcount >= 21 && (
                        <div className="pagination-container-1">
                          <PaginationControl
                            page={page}
                            between={4}
                            total={checkoutData?.totalcount}
                            // total={120}
                            limit={20}
                            changePage={page => {
                              setPage(page)
                              getList(page)
                              setPageToken(page)
                            }}
                            ellipsis={2}
                          />
                        </div>
                      )}
                      <Card className="mb-1">
                        <CardBody className="px-0 pt-0">
                          <div style={{ overflowX: "auto" }}>
                            <Row
                              className="px-2 py-3 border font-size-16 font-weight-semibold"
                              style={
                                window.innerWidth <= 768
                                  ? { display: "none" }
                                  : { background: "#b4bbc7" }
                              }
                              id="sticky header"
                            >
                              <Col md="4" lg="3">
                                Buyer
                              </Col>
                              <Col>
                                <Row>
                                  <Col md="4" lg="3" sm="4">
                                    Products & Vehicle
                                  </Col>
                                  <Col>Click History</Col>
                                </Row>
                              </Col>
                            </Row>
                            {checkoutData &&
                              checkoutData?.data?.map((item, index) => (
                                <Card className="mb-1" key={index}>
                                  <CardBody
                                    className="px-0 py-2"
                                    style={{
                                      background:
                                        index % 2 === 0 ? "#f8f9fa" : "#f0f2f6",
                                    }}
                                  >
                                    <Row className="align-items-center">
                                      <Col md="4" lg="3">
                                        {item?.name_user != "" && (
                                          <div className="font-weight-semibold px-3">
                                            <p className="mb-0">
                                              {item?.name_user}
                                            </p>
                                          </div>
                                        )}
                                        {item?.mobile_user && (
                                          <div className="font-weight-semibold px-3">
                                            <p className="mb-0">
                                              {item?.mobile_user}
                                            </p>
                                          </div>
                                        )}
                                        {item?.email_user && (
                                          <div className="font-weight-semibold px-3">
                                            <p className="mb-0">
                                              {item?.email_user}
                                            </p>
                                          </div>
                                        )}
                                      </Col>
                                      <Col>
                                        {item?.clicks_on
                                          ?.reverse()
                                          .map((clickData, clickIndex) => (
                                            <Row
                                              key={clickIndex}
                                              className="align-items-center py-1"
                                              style={{
                                                background:
                                                  clickIndex % 2 === 0
                                                    ? "#effeef"
                                                    : "#c3ffc3",
                                              }}
                                            >
                                              <Col md="4" lg="3" sm="4">
                                                {clickData?.cart_details?.map(
                                                  (cartDetail, cartIndex) => (
                                                    <div
                                                      key={cartIndex}
                                                      className="mb-2 px-3"
                                                    >
                                                      <p className="font-weight-semibold mb-0">
                                                        {
                                                          cartDetail?.seller_part_no
                                                        }
                                                      </p>
                                                      <p className="mb-0">
                                                        {cartDetail?.prod_name}
                                                      </p>
                                                      <p className="mb-0">
                                                        {
                                                          cartDetail
                                                            ?.vehicle_details
                                                            ?.make
                                                        }{" "}
                                                        {
                                                          cartDetail
                                                            ?.vehicle_details
                                                            ?.model
                                                        }{" "}
                                                        {
                                                          cartDetail
                                                            ?.vehicle_details
                                                            ?.year
                                                        }
                                                      </p>
                                                    </div>
                                                  )
                                                )}
                                              </Col>
                                              <Col className="">
                                                {clickData?.click_history
                                                  ?.reverse()
                                                  .map((click, cartIndex) => (
                                                    <div
                                                      key={cartIndex}
                                                      className="mb-1 d-flex px-3"
                                                    >
                                                      <p className="mb-0">
                                                        {moment(
                                                          click?.event_time[
                                                            "$date"
                                                          ]
                                                        ).format(
                                                          "DD MMM Y, h:mm:ss a"
                                                        )}{" "}
                                                        - {"  "}
                                                        <span className="text-capitalize mb-0 px-1">
                                                          {click?.event}
                                                        </span>
                                                        {click?.event?.includes(
                                                          "order success"
                                                        ) && (
                                                          <span>
                                                            <svg
                                                              width="18"
                                                              height="12"
                                                              viewBox="0 0 18 13"
                                                              fill="none"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                              <path
                                                                d="M17 1L6 12L1 7"
                                                                stroke="#448B44"
                                                                stroke-width="2"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                              />
                                                            </svg>
                                                          </span>
                                                        )}
                                                      </p>
                                                    </div>
                                                  ))}
                                              </Col>
                                            </Row>
                                          ))}
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              ))}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

checkOutReport.propTypes = {
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default checkOutReport
