import React from 'react';
import loader from "../assets/images/loader.gif";
export const Loader = (props) => {

    return (
        <div>
            <div className="fallback-spinner">
                <div className="loading component-loader">
                    <img src={loader} style={{ width: "90px" }}></img>
                </div>
            </div>
        </div>
    );
}