import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
} from "reactstrap"

import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

//redux

const OrderCards = props => {
  const history = useHistory()

  let col = ""
  if (props.title == "TOTAL ORDERS") {
  }

  return (
    <React.Fragment>
      <Col xs="6" sm="4" md="4" className="pb-4 h-100">
        <Card
          className="mb-0"
          style={props.bg}
          onClick={() => history.push(props.url)}
        >
          <CardBody className="py-3 px-2">
            <p className="fw-medium text-center font-size-16 text-wrap mb-2">
              {props.title}
            </p>
            <h6 className="text-center font-size-14 mb-2">{props.number}</h6>
            <h6 className={`mb-0 text-center font-size-16`}>{props.amount}</h6>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

OrderCards.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(OrderCards)
