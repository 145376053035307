import {
    Input
} from "reactstrap";
import Select from "react-select";
import { useState } from "react";

export const CustomInput = (props) => {
    const { name,
        defaultValue, value,
        onChange, setSelectedPackage, type } = props;
    return (<Input
        name={name}
        value={value}
        defaultValue={defaultValue}
        type={type ? type : "text"}
        className="form-control"
        onChange={e => {
            if (setSelectedPackage != undefined) {
                setSelectedPackage(e);
            }
            onChange(e);
        }}
    />)
}

export const CustomCheckbox = (props) => {
    const { name,
        defaultValue, value,
        onChange, type, checked } = props;

    const [ischecked, setischecked] = useState(checked)

    return (<input
        name={name}
        value={value}
        checked={ischecked}
        defaultValue={defaultValue}
        type={type ? type : "text"}
        className="form-check-input font-size-20"
        onChange={(e) => {
            setischecked(!ischecked)
            onChange(e.target.checked);
        }}
    />)
}

export const CustomSelect = (props) => {
    const { options, name,
        defaultValue, value,
        onChange, setSelectedPackage } = props;
    return (<Select
        classNamePrefix="select"
        name={name}
        options={options}
        value={value}
        defaultValue={defaultValue}
        onChange={e => {
            if (setSelectedPackage != undefined) {
                setSelectedPackage(e);
            }
            onChange(e);
        }}
    />)
}