import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import MetaTags from 'react-meta-tags';

import {
    Button,
    Card,
    CardBody,
    CardSubtitle,
    CardTitle,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    TabContent,
    Table,
    TabPane,
} from "reactstrap"
import Select from "react-select"
import Dropzone from "react-dropzone"
import { API_URL } from "../../helpers/api_helper";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { CustomInput, CustomSelect } from "../Ecommerce/customInputs";
import { post, get } from "../../helpers/api_helper";
import axios from 'axios';
import StarRatings from "react-star-ratings"
import { Loader } from "../../helpers/loader";
import { useParams } from "react-router-dom";
import { ErrorToast } from "../../helpers/toast";
import { useHistory } from "react-router-dom";
import { success, error } from "../../service/alerts";

const AddVehicles = () => {
    const { handleSubmit, errors, control, setValue, watch, register, unregister } = useForm({
        mode: 'onChange'
    });
    const history = useHistory();
    const [selectedFiles, setselectedFiles] = useState([])
    const [make, setMake] = useState([]);
    const [model, setModel] = useState([]);
    const [loader, setLoader] = useState(false);
    const [imageLoader, setImageLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    const [message, setMessage] = useState("")
    const { id } = useParams();
    const getProductDetails = async (id) => {
        get(`/api/v1/add_vehicle_details/?version_id=${id}`).then(res => {
            const { data } = res.data;
            setValue("make", { value: data[0].make_id, label: data[0].make });
            setValue("model", { value: data[0].model_id, label: data[0].model });
            getModel({ value: data[0].model_id, label: data[0].model });
            const fuelType = [
                { value: "petrol", label: "Petrol" },
                { value: "diesel", label: "Diesel" },
                { value: "electric", label: "Electric" }]
            const fuel = fuelType.filter(items => {
                return items.value == data[0].fuel_type
            })
            setValue("fuel_type", fuel ? fuel[0] : "");
            setValue("isPresent", data[0].isPresent ? data[0].isPresent : false);
            setValue("from_year", data[0].from_year ? data[0].from_year : "");
            setValue("to_year", data[0].to_year ? data[0].to_year : "");
            setValue("chassis_code", data[0].chassis_code ? data[0].chassis_code : "");
            setValue("version", data[0].version ? data[0].version : "");
            setValue("description", data[0].description ? data[0].description : "");
            setselectedFiles(data[0].image ? data[0].image : [])

        }).catch(err => {

        });
    }
    const getMake = async () => {
        const result = await get("/api/v1/vehiclemake/");
        if (result.data) {
            let inputs = [];
            result.data && result.data.map(item => {
                if (typeof (item.make) === "string") {
                    inputs.push({
                        label: item.make,
                        value: item._id["$oid"]
                    })
                }
            })
            setMake(inputs)
        } else {
            setMake([]);
        }
    }
    const getModel = async (id) => {
        const result = await get(`/api/v1/vehiclemodel/?id=${id.value}`);
        if (result.data) {
            let inputs = [];
            result.data && result.data.map(item => {
                inputs.push({
                    label: item.model,
                    value: item._id["$oid"]
                })
            })
            setModel(inputs)
        } else {
            setModel([]);
        }
    }
    useEffect(() => {
        getMake();
    }, []);
    useEffect(() => {
        if (id) { getProductDetails(id); }
    }, [id])
    const handleAcceptedFiles = (acceptedFiles) => {
        let formData = new FormData();
        formData.append("name", acceptedFiles[0].name);
        formData.append("type", "product");
        formData.append("file", acceptedFiles[0]);
        setImageLoader(true);
        fetch( API_URL + `/api/v1/products/uploadimage/`,      
        {
                method: 'POST',
                body: formData
            }
        )
            .then(response => response.json())
            .then(res => {
                let selected = [];
                selected.push(`${res.url}`);
                setselectedFiles(selected);
                setImageLoader(false);
            })
    }
    const onSubmit = (items) => {
        // if (selectedFiles.length == 0) {
        //     error("Please add image");
        //     return
        // }
        let values = {
            image: selectedFiles && selectedFiles.length > 0 ? selectedFiles[0] : "",
            fuel_type: items.fuel_type.value,
            make_id: items.make.value,
            model_id: items.model.value,
            chassis_code: items.chassis_code,
            description: items.description,
            version: items.version,
            from_year: items.from_year,
            to_year: items.to_year

        }
        if (items.hasOwnProperty("isPresent") == true) {
            values.isPresent = Boolean(items.isPresent)
        }
        setLoader(true);
        try {
            let urls = "/api/v1/add_vehicle_details/";
            if (id) {
                urls = "/api/v1/update_version/";
                values.id = id;
            }
            post(urls, values).then(res => {
                success("able to create vehicle");
                setLoader(false);
            }).catch(err => {
                setLoader(false);
                error(err.response?.data?.message)
            });


        } catch (err) {
            setLoader(false);
        }
    }
    const presentItem = watch("isPresent", "false");
    return (
        <React.Fragment>
            <div className="page-content">
                {showError && <ErrorToast
                    message={message}
                    showError={showError}
                    setShowError={setShowError} />}
                <MetaTags>
                    <title>Add Model |  BrakeUp</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Commercial" breadcrumbItem="Add Model" />

                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Basic Information</CardTitle>
                                    <CardSubtitle className="mb-4">
                                        Fill all information below
                                    </CardSubtitle>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Row>
                                            <Col sm="5">
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Select Make
                                                    </Label>
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                        style={{
                                                            float: "right",
                                                            textAlign: "right",
                                                            cursor: "pointer",
                                                            color: "green",
                                                        }}
                                                        onClick={() => history.push("/addMake/")}
                                                    >
                                                        Add New
                                                    </Label>
                                                    <Col sm={12}>
                                                        <div className="form-floating mb-3">
                                                            <Controller
                                                                as={<CustomSelect />}
                                                                defaultValue={null}
                                                                name={"make"}
                                                                options={make}
                                                                onChange={([e]) => {
                                                                    return { value: e };
                                                                }}
                                                                control={control}
                                                                rules={{ required: true }}
                                                                setSelectedPackage={getModel}
                                                            />
                                                            {errors.make && <span style={{ color: "red" }}>Make is required.</span>}

                                                        </div>
                                                    </Col>
                                                </div>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Select Model
                                                    </Label>
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                        style={{
                                                            float: "right",
                                                            textAlign: "right",
                                                            cursor: "pointer",
                                                            color: "green",
                                                        }}
                                                        onClick={() => history.push("/addModels/")}
                                                    >
                                                        Add New
                                                    </Label>
                                                    <Col sm={12}>
                                                        <div className="form-floating mb-3">
                                                            <Controller
                                                                as={<CustomSelect />}
                                                                defaultValue={null}
                                                                name={"model"}
                                                                options={model}
                                                                onChange={([e]) => {
                                                                    return { value: e };
                                                                }}
                                                                control={control}
                                                                rules={{ required: true }}
                                                            />
                                                            {errors.model && <span style={{ color: "red" }}>Model is required.</span>}

                                                        </div>
                                                    </Col>
                                                </div>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Fuel Type
                                                    </Label>
                                                    <Col sm={12}>
                                                        <div className="form-floating mb-3">
                                                            <Controller
                                                                as={<CustomSelect />}
                                                                defaultValue={null}
                                                                name={"fuel_type"}
                                                                options={[
                                                                    { value: "petrol", label: "Petrol" },
                                                                    { value: "diesel", label: "Diesel" },
                                                                    { value: "electric", label: "Electric" }]}
                                                                onChange={([e]) => {
                                                                    return { value: e };
                                                                }}
                                                                control={control}
                                                                rules={{ required: true }}
                                                            />
                                                            {errors.fuel_type && <span style={{ color: "red" }}>fuel type is required.</span>}

                                                        </div>
                                                    </Col>
                                                </div>
                                                <Row>
                                                    <Col sm="4">
                                                        <div className="mb-3">
                                                            <Label htmlFor="productname">From Year</Label>
                                                            <Controller
                                                                as={<CustomInput />}
                                                                defaultValue={null}
                                                                name="from_year"
                                                                onChange={([e]) => {
                                                                    return { value: e };
                                                                }}
                                                                control={control}
                                                                rules={{ required: true }}
                                                            />
                                                            {errors.from_year && <span style={{ color: "red" }}>From Year is required.</span>}
                                                        </div>
                                                    </Col>
                                                    <Col sm="4">
                                                        <div className="mb-3">
                                                            <Label htmlFor="productname"></Label><br></br>
                                                            <input
                                                                ref={register({ required: false })}
                                                                name={"isPresent"}
                                                                value={"true"}
                                                                type="checkbox"
                                                            />
                                                            <span>Present</span>
                                                        </div>
                                                    </Col>
                                                    {presentItem == false && <Col sm="4">
                                                        <div className="mb-3">
                                                            <Label htmlFor="productname">To Year</Label>
                                                            <Controller
                                                                as={<CustomInput />}
                                                                defaultValue={null}
                                                                name="to_year"
                                                                onChange={([e]) => {
                                                                    return { value: e };
                                                                }}
                                                                control={control}
                                                                rules={{ required: true }}
                                                            />
                                                            {errors.to_year && <span style={{ color: "red" }}>To Year is required.</span>}
                                                        </div>
                                                    </Col>}
                                                </Row>
                                                <div className="mb-3">
                                                    <Label htmlFor="productname">Chassis Code</Label>
                                                    <Controller
                                                        as={<CustomInput />}
                                                        defaultValue={null}
                                                        name="chassis_code"
                                                        onChange={([e]) => {
                                                            return { value: e };
                                                        }}
                                                        control={control}
                                                        rules={{ required: false }}
                                                    />
                                                    {errors.chassis_code && <span style={{ color: "red" }}>Chassis Code is required.</span>}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="productname">Version</Label>
                                                    <Controller
                                                        as={<CustomInput />}
                                                        defaultValue={null}
                                                        name="version"
                                                        onChange={([e]) => {
                                                            return { value: e };
                                                        }}
                                                        control={control}
                                                        rules={{ required: false }}
                                                    />
                                                    {errors.version && <span style={{ color: "red" }}>Version is required.</span>}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="productname">Description</Label>
                                                    <Controller
                                                        as={<CustomInput />}
                                                        defaultValue={null}
                                                        name="description"
                                                        onChange={([e]) => {
                                                            return { value: e };
                                                        }}
                                                        control={control}
                                                        rules={{ required: false }}
                                                        type={"textarea"}
                                                    />
                                                    {errors.description && <span style={{ color: "red" }}>Description is required.</span>}
                                                </div>
                                            </Col>
                                            <Col sm="5">
                                                <div className="mb-3">
                                                    <Dropzone
                                                        onDrop={acceptedFiles => {
                                                            handleAcceptedFiles(acceptedFiles)
                                                        }}
                                                    >
                                                        {({ getRootProps, getInputProps }) => (
                                                            <div
                                                                {...getRootProps()}
                                                                style={{
                                                                    height: "160px",
                                                                    border: "1px dashed #60e0ff",
                                                                    textAlign: "center",
                                                                    cursor: "pointer"
                                                                }}
                                                            >
                                                                <input {...getInputProps()} />
                                                                {imageLoader && <Loader />}
                                                                {selectedFiles.length == 0 && <h4 style={{ marginTop: "64px" }}>Add Image</h4>}
                                                                {selectedFiles.map((f, i) => {
                                                                    return (
                                                                        <img
                                                                            data-dz-thumbnail=""
                                                                            height="80"
                                                                            className="avatar-sm rounded bg-light"
                                                                            style={{ width: "100%", height: "100%" }}
                                                                            alt={"image"}
                                                                            src={f}
                                                                        />)
                                                                })
                                                                }
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="d-flex flex-wrap gap-2">
                                            {loader && <Loader />}
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="btn "
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default AddVehicles
